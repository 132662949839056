import { styled } from "@mui/material/styles";
import AutocompleteComponent from "@mui/material/Autocomplete";

export const Autocomplete = styled(AutocompleteComponent)`
  &.MuiAutocomplete-root {
    .MuiInputBase-root {
      border-radius: 8px;
      border: 1px solid
        ${({ theme }) => theme.kinetic.v3.palette.chroma.neutraltint[400].css()};
      padding: 0px 12px;
      font-size: 14px;
    }

    &.Mui-focused.Mui-expanded {
      .MuiInputBase-root {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
      }
    }
  }

  .MuiAutocomplete-tag {
    background-color: ${({ theme }) =>
      theme.kinetic.v3.palette.chroma.neutraltint[200].css()};
    border-radius: 4px;
    font-size: 12px;
    color: ${({ theme }) =>
      theme.kinetic.v3.palette.chroma.neutralshade[400].css()};
    margin: 2px;
    max-width: calc(100% - 12px);
  }

  .MuiAutocomplete-tag .MuiChip-deleteIcon {
    color: ${({ theme }) => theme.colors.background[5]};
    &:hover {
      color: ${({ theme }) =>
        theme.kinetic.v3.palette.chroma.neutralshade[600].css()};
    }
  }

  .MuiOutlinedInput-root {
    padding: 0;
    max-height: 250px;
    overflow: auto;
  }

  .MuiAutocomplete-endAdornment {
    right: 10px;
  }
`;
