import * as React from "react";
import {
  Body,
  BodyCorrect,
  BodyFuzzyCorrect,
  BodyFuzzyIncorrect,
  BodyIncorrect,
  BodyTime,
  Speaker,
} from "./styles";
import { secondsToTime } from "@bigspring/core-components/src/utils/secondsToTime";
import { Box } from "@mui/material";

export type TranscriptHighlightTextProps = {
  text?: string | null;
  shouldHighlight?: boolean;
  highlightsExpected?: Array<string>;
  highlightsFuzzyExpected?: Array<string>;
  highlightsIncorrect?: Array<string>;
  highlightsFuzzyIncorrect?: Array<string>;
  startTime?: string | null;
  isMultipleSpeakers?: boolean;
  speakerRoleToShow?: string | null;
  forConversation?: boolean;
};

export function TranscriptHighlightText({
  text,
  shouldHighlight = true,
  highlightsExpected = [],
  highlightsFuzzyExpected = [],
  highlightsIncorrect = [],
  highlightsFuzzyIncorrect = [],
  startTime,
  speakerRoleToShow,
  isMultipleSpeakers,
  forConversation,
}: TranscriptHighlightTextProps) {
  // Create a regular expression to match any of the highlight words
  const regex = new RegExp(
    `(${[
      ...highlightsExpected,
      ...highlightsFuzzyExpected,
      ...highlightsIncorrect,
      ...highlightsFuzzyIncorrect,
    ]?.join("|")})`,
    "gi"
  );

  let Correct;
  let FuzzyCorrect;
  let Incorrect;
  let FuzzyIncorrect;

  if (shouldHighlight) {
    Correct = BodyCorrect;
    FuzzyCorrect = BodyFuzzyCorrect;
    Incorrect = BodyIncorrect;
    FuzzyIncorrect = BodyFuzzyIncorrect;
  } else {
    Correct = Body;
    FuzzyCorrect = Body;
    Incorrect = Body;
    FuzzyIncorrect = Body;
  }

  // Split the text into parts based on the regular expression
  const parts = text?.split(regex);

  return (
    <Box display={"flex"} gap={"10px"}>
      <Box>
        <BodyTime $forConversation={forConversation}>
          {startTime && secondsToTime(startTime)}
        </BodyTime>
      </Box>
      <Box>
        {isMultipleSpeakers && speakerRoleToShow && (
          <Speaker>{speakerRoleToShow}</Speaker>
        )}
        <span>
          {parts?.map((part: string) => {
            if (
              highlightsExpected?.some(
                (highlight: string) =>
                  part.toLowerCase() === highlight.toLowerCase()
              )
            ) {
              return <Correct key={part}>{part}</Correct>;
            } else if (
              highlightsFuzzyExpected?.some(
                (highlight: string) =>
                  part.toLowerCase() === highlight.toLowerCase()
              )
            ) {
              return <FuzzyCorrect key={part}>{part}</FuzzyCorrect>;
            } else if (
              highlightsIncorrect?.some(
                (highlight: string) =>
                  part.toLowerCase() === highlight.toLowerCase()
              )
            ) {
              return <Incorrect key={part}>{part}</Incorrect>;
            } else if (
              highlightsFuzzyIncorrect?.some(
                (highlight: string) =>
                  part.toLowerCase() === highlight.toLowerCase()
              )
            ) {
              return <FuzzyIncorrect key={part}>{part}</FuzzyIncorrect>;
            } else {
              return <Body key={part}>{part}</Body>;
            }
          })}
        </span>
      </Box>
    </Box>
  );
}
