export function secondsToTime(seconds: string) {
  const secondsNumbers = Number(seconds);

  if (Number.isNaN(secondsNumbers)) {
    return seconds;
  }

  const h = Math.floor(secondsNumbers / 3600),
    m = Math.floor((secondsNumbers % 3600) / 60),
    s = Math.floor(secondsNumbers % 60)
      .toString()
      .padStart(2, "0");

  if (h > 0) {
    return `${h}:${m}:${s}`;
  }

  return `${m}:${s}`;
}
