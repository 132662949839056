import React from "react";
import { Box } from "../Box";
import { ArticulationLabel, ArticulationSlider, DurationLabel } from "./styles";

import { Tooltip } from "@mui/material";

export interface RangeSliderProps {
  progress: number;
  progressString?: string;
  topStartLabel?: string;
  topEndLabel?: string;
  bottomStartLabel?: string;
  bottomEndLabel?: string;
  tooltip?: string;
  gradientType?: "sine" | "linear";
}

export function RangeSlider({
  progress,
  progressString,
  topStartLabel,
  topEndLabel,
  bottomStartLabel,
  bottomEndLabel,
  tooltip,
  gradientType = "sine",
}: RangeSliderProps) {
  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"} mb={"5px"}>
        {topStartLabel && (
          <ArticulationLabel>{topStartLabel}</ArticulationLabel>
        )}
        {topEndLabel && <ArticulationLabel>{topEndLabel}</ArticulationLabel>}
      </Box>
      <ArticulationSlider progress={progress} gradientType={gradientType}>
        <Tooltip title={tooltip} arrow>
          <div id="thumb" />
        </Tooltip>
        {progressString && <div id="duration">{progressString}</div>}
      </ArticulationSlider>
      <Box display={"flex"} justifyContent={"space-between"} mb={"5px"}>
        <DurationLabel $visibility={bottomStartLabel ? "visible" : "hidden"}>
          {bottomStartLabel}
        </DurationLabel>
        <DurationLabel $visibility={bottomEndLabel ? "visible" : "hidden"}>
          {bottomEndLabel}
        </DurationLabel>
      </Box>
    </>
  );
}
