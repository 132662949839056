// Disable Video.js analytics
import videojs from "video.js";
import { getOptions } from "./options";
import { getSources } from "./options/sources";
import { getMuxMetadata } from "./options/tracking";
import { toggleDefaultCaptions } from "./textTracks";
import {
  saveCaptionsSetting,
  restoreCaptionsSetting,
} from "./storage/textTracks";
import {
  saveCurrentTime,
  restoreCurrentTime,
  removeCurrentTime,
} from "./storage/progress";
import type {
  EffectHandler,
  EventType,
  EventHandlers,
  Props,
  VideoJsPlayer,
  VideoPlayerOptions,
} from "../types";

// Setup custom Video.js components
import "../components/LoadingSpinner";
import "../components/SeekBar";

if (typeof window !== "undefined") {
  window.HELP_IMPROVE_VIDEOJS = false;
}

/**
 * Get Video.js initialization callback
 *
 * @param props
 * @returns videojs.ReadyCallback
 */
function getReadyCallback(props: Props): videojs.ReadyCallback {
  function readyCallback(this: VideoJsPlayer) {
    // TODO: This seems weird, we need to remove this alias
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const player = this;

    if (!player) {
      throw new Error("Failed to initialize Video.js");
    }

    // Convert captions menu to a toggle button
    // TODO: re-enable menu once multiple text tracks are supported for a video
    const subsCapsButton = player.controlBar.getChild(
      "SubsCapsButton"
    ) as videojs.TextTrackButton;

    subsCapsButton.off();
    subsCapsButton.children().forEach((child: any) => child.off());
    subsCapsButton?.on(["click", "tap"], () => {
      toggleDefaultCaptions(player);
      saveCaptionsSetting(player);
    });

    // Fire `seekstart` event on ProgressControl and SeekBar mousedown
    const progressControl = player.controlBar.getChild(
      "ProgressControl"
    ) as videojs.ProgressControl;
    const seekBar = progressControl?.getChild("SeekBar");

    const seekStartHandler = () => player.trigger("seekstart");
    progressControl?.on("mousedown", seekStartHandler);
    if (seekBar) {
      seekBar.on("mousedown", seekStartHandler);
    }

    // Bind player event handlers
    const standardEventHandlers: EventHandlers = {
      pause: props.onPause,
      waiting: props.onWaiting,
      playing: props.onPlaying,
      seekstart: props.onSeekStart,
      texttrackchange: props.onTextTrackChange,
      loadeddata: props.onLoadedData,
      loadedmetadata: props.onLoadedMetadata,
      dispose: props.onDispose,
      useractive: props.onUserActive,
      userinactive: props.onUserInactive,
      fullscreenchange: props.onFullscreenChange,
    };
    const standardEventTypes = Object.keys(
      standardEventHandlers
    ) as EventType[];
    standardEventTypes.forEach((eventType) => {
      const eventHandler = standardEventHandlers[eventType];
      if (eventHandler) {
        player.on(eventType, () => eventHandler(player));
      }
    });

    player.on("play", () => {
      restoreCaptionsSetting(player);
      props.onPlay?.(player);
    });

    player.one(
      typeof props.readyEvent === "string"
        ? props.readyEvent
        : "loadedmetadata",
      () => {
        toggleDefaultCaptions(player, false);

        if (props.storeCurrentTime) {
          restoreCurrentTime(player);
        }

        props.onReady?.(player);
      }
    );

    player.on("seeking", () => {
      player.playbackRate(0);

      // Block keyboard seek past maxSeekTime if it is set
      // SeekBar is patched separately to prevent mouse click forward seeking
      if (
        props.preventSeekAhead &&
        player.maxSeekTime &&
        player.currentTime() > player.maxSeekTime
      ) {
        player.currentTime(player.maxSeekTime);
      } else {
        props.onSeeking?.(player);
      }
    });

    player.on("seeked", () => {
      player.playbackRate(1);
      props.onSeeked?.(player);
    });

    player.on("timeupdate", () => {
      if (props.storeCurrentTime) {
        saveCurrentTime(player);
      }

      // Update maxSeekTime if forward seeking is disallowed
      if (props.preventSeekAhead) {
        player.maxSeekTime = Math.max(
          player.currentTime(),
          player.maxSeekTime || 0
        );
      }

      props.onTimeUpdate?.(player);
    });

    player.on("ended", () => {
      if (props.storeCurrentTime) {
        removeCurrentTime(player);
      }

      props.onEnded?.(player);
    });

    player.on("error", () => {
      const error = new Error(player.error()?.message || "Video player error");
      console.error(error);

      return props.onError?.(error, player);
    });
  }

  return readyCallback;
}

/**
 * Initialize player on mount
 *
 * @param props
 * @param playerRef
 * @param videoRef
 * @returns void
 */
export const initPlayer: EffectHandler = (props, playerRef, videoRef) => {
  if (!videoRef.current) {
    return;
  }

  playerRef.current = videojs(
    videoRef.current,
    getOptions(props),
    getReadyCallback(props)
  );
};

/**
 * Update Video.js player options on props change
 *
 * @param props
 * @param playerRef
 * @param videoRef
 * @returns void
 */
export const updatePlayer: EffectHandler = (props, playerRef, videoRef) => {
  if (!videoRef.current) {
    return;
  }

  const player = playerRef.current;
  const { options } = props;

  if (!player || !options) {
    return;
  }

  const isDefined = (key: keyof VideoPlayerOptions) =>
    options && typeof options[key] !== "undefined";

  if (isDefined("autoplay")) {
    player.autoplay(options.autoplay as videojs.Autoplay);
  }
  if (isDefined("controls") || typeof props.isThumbnail !== "undefined") {
    player.controls(!!options?.controls && !props.isThumbnail);
  }
  if (isDefined("height")) {
    player.height(options.height as number);
  }
  if (isDefined("loop")) {
    player.loop(options.loop as boolean);
  }
  if (isDefined("muted")) {
    player.muted(options.muted as boolean);
  }
  if (isDefined("poster")) {
    player.poster(options.poster as string);
  }
  if (isDefined("preload")) {
    player.preload(!!options.preload && options.preload !== "none");
  }
  if (isDefined("width")) {
    player.width(options.width as number);
  }
  if (isDefined("aspectRatio")) {
    player.aspectRatio(options.aspectRatio as string);
  }
  if (isDefined("responsive")) {
    player.responsive(options.responsive as boolean);
  }
  if (isDefined("src") || isDefined("sources")) {
    const currentSrc = player.src();
    const newSrc = getSources(options, props.isThumbnail);
    if (!newSrc.find(({ src }) => currentSrc === src)) {
      player.src(newSrc);
      // Reset Mux video tracking
      // https://docs.mux.com/guides/data/monitor-video-js#4-changing-the-video
      const muxMetadata = getMuxMetadata(props);
      (player as any).mux.emit("videochange", muxMetadata);
    }
  }
  player.fluid(!!props.keepAspectRatio);
  player.fill(!props.keepAspectRatio);
};

/**
 * Handle component unmount
 *
 * @param playerRef
 */
export const disposePlayer: EffectHandler = (props, playerRef) => {
  if (!playerRef.current) {
    return;
  }
  // Dispose Video.js player
  playerRef.current.dispose();
  // Unbind event listeners
  playerRef.current.off();
  playerRef.current = null;
};
