import { css, styled } from "@mui/material/styles";

export const BodyTemplate = css`
  font-family: "SF Pro Text", "Roboto", var(--bigspring-primary-font);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const Body = styled("span")`
  ${BodyTemplate};
  color: ${({ theme }) =>
    theme.kinetic.v3.palette.chroma.neutralshade[100].css()};
`;

export const BodyCorrect = styled("span")`
  ${BodyTemplate};
  color: var(--Positive-600-shade, #0d4703);
  background: var(--Positive-600-tint, #bce8b5);
  border-radius: 4px;
  font-weight: 700;
`;

export const BodyFuzzyCorrect = styled("span")`
  ${BodyTemplate};
  color: var(--Positive-Baseline, #20b107);
  background: var(--Positive-300-tint, #e9f7e6);
  border-radius: 4px;
  font-weight: 700;
`;

export const BodyIncorrect = styled("span")`
  ${BodyTemplate};
  color: var(--Urgency-600-shade, #781e12);
  background: var(--Urgency-600-tint, #f9b1a7);
  border-radius: 4px;
  font-weight: 700;
`;

export const BodyFuzzyIncorrect = styled("span")`
  ${BodyTemplate};
  color: var(--Urgency-Baseline, #ef3c24);
  background: var(--Urgency-300-tint, #fdece9);
  border-radius: 4px;
  font-weight: 700;
`;

export const BodyTime = styled("span")<{ $forConversation?: boolean }>`
  color: ${({ theme }) =>
    theme.kinetic.v3.palette.chroma.neutraltint[900].css()};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: 0.24px;
  display: flex;
  min-width: ${({ $forConversation }) => ($forConversation ? "auto" : "42px")};
`;

export const Speaker = styled("div")`
  color: ${({ theme }) =>
    theme.kinetic.v3.palette.chroma.neutraltint[900].css()};
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.2px;
`;
