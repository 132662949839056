import { styled } from "@mui/material/styles";
import { Button as BaseButton } from "../Button";

export const Root = styled("div")`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Carousel = styled("div")`
  position: relative;
  height: calc(100% - 64px);
  width: 100%;
  overflow: hidden;
`;

export const CardWrapper = styled("div")<{ $isActive: boolean }>`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0)};
  pointer-events: ${({ $isActive }) => ($isActive ? "auto" : "none")};
  transition: opacity 0.2s ease;
  overflow-y: ${({ $isActive }) => ($isActive ? "auto" : "hidden")};
`;

export const NavBarContainer = styled("div", {
  label: "NavBarContainer",
  name: "NavBarContainer",
})`
  background-color: white;
  width: 100%;
  border-top: 1px solid
    ${({ theme }) => theme.kinetic.v3.palette.chroma.neutraltint[300].css()};
  display: flex;
  position: absolute;
  bottom: 0;
  justify-content: center;
`;

export const NavBarContentWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-inline: 40px;
  min-width: 600px;
  padding-block: 12px;

  @media (max-width: 680px) {
    min-width: fill-available;
  }

  @media (max-width: 576px) {
    margin-inline: 20px;
    padding-block: 12px;
  }
`;

// NAVIGATION
type NavigationButtonProps = {
  $isVisible?: boolean;
};

const NavigationButton = styled(BaseButton)<NavigationButtonProps>`
  width: 44px;
  height: 44px;

  background-color: ${({ theme }) =>
    theme.kinetic.v3.palette.chroma.white.css()};
  border: 1px solid
    ${({ theme }) => theme.kinetic.v3.palette.chroma.neutraltint[300].css()} !important;
  border-radius: 50%;
  box-shadow: none;

  &:hover {
    background-color: ${({ theme }) =>
      theme.kinetic.v3.palette.chroma.white.css()};
    box-shadow: none !important;
  }

  svg {
    fill: ${({ theme }) =>
      theme.kinetic.v3.palette.chroma.neutraltint[900].css()};
  }

  &:disabled {
    background: ${({ theme }) =>
      theme.kinetic.v3.palette.chroma.neutraltint[200].css()} !important;
    box-shadow: none;

    svg {
      fill: ${({ theme }) =>
        theme.kinetic.v3.palette.chroma.neutraltint[500].css()} !important;
    }
  }
`;

export const LeftNavigationButton = styled(NavigationButton)`
  visibility: ${({ $isVisible }) => ($isVisible ? "visible" : "hidden")};
  box-shadow: none;
`;

export const RightNavigationButton = styled(LeftNavigationButton)`
  animation: blink 900ms ease infinite;
  &:disabled {
    animation: none;
  }
  background-color: ${({ theme }) =>
    theme.kinetic.v3.palette.chroma.primarybaseline.css()};
  &:hover {
    background-color: ${({ theme }) =>
      theme.kinetic.v3.palette.chroma.primarybaseline.css()};
  }

  svg {
    fill: ${({ theme }) => theme.kinetic.v3.palette.chroma.white.css()};
  }

  @keyframes blink {
    0% {
      box-shadow: 0 0 0
        ${({ theme }) => theme.kinetic.v3.palette.chroma.primarybaseline.css()};
    }
    50% {
      box-shadow: 0 0 14px
        ${({ theme }) => theme.kinetic.v3.palette.chroma.primarybaseline.css()};
    }
    100% {
      box-shadow: 0 0 0
        ${({ theme }) => theme.kinetic.v3.palette.chroma.primarybaseline.css()};
    }
  }
`;

type ProgressProps = { $progress: number };

export const ProgressTrack = styled("div")`
  border-radius: ${({ theme }) => theme.kinetic.borderRadius.sm};
  border: 1px solid
    ${({ theme }) => theme.kinetic.v3.palette.chroma.neutraltint[300].css()};
  height: 8px;
  width: 100%;
  background: ${({ theme }) =>
    theme.kinetic.v3.palette.chroma.neutraltint[100].css()};
  position: relative;
  margin-inline: 20px;
  box-sizing: content-box;
`;

export const ProgressBar = styled("div")<ProgressProps>`
  border-radius: ${({ theme }) => theme.kinetic.borderRadius.sm};
  height: 8px;
  width: ${({ $progress }) => 100 * $progress}%;
  min-width: 8px;
  max-width: 100%;
  background: ${({ theme }) =>
    theme.kinetic.v3.palette.chroma.positivebaseline.css()};
  transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1);
`;

ProgressBar.defaultProps = {
  role: "progressbar",
  "aria-label": "Progress",
  "aria-valuenow": 0,
};
