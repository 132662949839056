import { css, styled } from "@mui/material/styles";
import { ThemeColor } from "./types";

export const Container = styled("div")<{ $fitContent?: boolean }>`
  width: ${({ $fitContent }) => ($fitContent ? "fit-content" : "100%")};
  height: 100%;
  margin: auto;
  display: block;
  position: relative;
  scroll-snap-align: center; // Snap to center of card
  scroll-snap-stop: always; // Swipe one card at a time
  @supports (-webkit-touch-callout: none) {
    overflow-y: auto;
  }
`;

Container.defaultProps = {
  role: "group",
  "aria-roledescription": "card",
};

export const Content = styled("div")<{
  $backgroundImageUrl?: string;
  $imagePosition?: string;
  $themeColor?: ThemeColor;
}>`
  width: inherit;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  z-index: 1;
  flex-direction: column;

  ${({ theme, $backgroundImageUrl, $themeColor }) => {
    let backgroundColor: string;
    let color: string;

    switch ($themeColor) {
      default:
      case "light":
        color = theme.kinetic.v2.palette.rgb.grayTextDark;
        backgroundColor = theme.kinetic.v2.palette.rgb.white;
        break;
      case "negative":
        color = theme.kinetic.v2.palette.rgb.negative;
        backgroundColor = theme.kinetic.v2.palette.rgb.negativeBg;
        break;
    }

    if ($backgroundImageUrl) {
      return css`
        color: ${color} !important;
      `;
    }

    return css`
      color: ${color} !important;
      background: ${backgroundColor};
    `;
  }}
`;

export const AlignedContent = styled("div")<{
  $align?: "left" | "center" | "right";
  $verticalAlign?: "top" | "center" | "bottom";
  $hasOverlay?: boolean;
}>`
  padding: 20px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: ${({ $align }) => ($align ? $align : "left")};
  align-items: ${({ $align }) => {
    switch ($align) {
      case "center":
        return "center";
      case "right":
        return "flex-end";
      case "left":
      default:
        return "flex-start";
    }
  }};
  justify-content: ${({ $verticalAlign }) => {
    switch ($verticalAlign) {
      case "top":
        return "flex-start";
      case "bottom":
        return "flex-end";
      case "center":
      default:
        return "center";
    }
  }};

  background-color: ${({ $hasOverlay }) =>
    $hasOverlay ? "rgba(0, 0, 0, 0.6)" : undefined};
  color: ${({ theme, $hasOverlay }) =>
    $hasOverlay ? theme.kinetic.v2.palette.rgb.white : undefined};

  > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;
