import React from "react";
import {
  AutocompleteProps,
  createFilterOptions,
} from "@mui/material/Autocomplete";
import { DropdownThickIcon } from "../../../../icons";
import { TextField, TextFieldProps } from "../TextField";
import { Autocomplete } from "./styles";
import { AutocompleteValue } from "@mui/material/useAutocomplete";

export { createFilterOptions };

export type { AutocompleteValue };
export type ComboBoxPropsV2<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
> = Omit<
  AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>,
  "renderInput"
> & {
  textFieldProps?: TextFieldProps;
};

export function ComboBoxV2<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  textFieldProps,
  ...props
}: ComboBoxPropsV2<T, Multiple, DisableClearable, FreeSolo>) {
  return (
    <Autocomplete
      {...(props as any)}
      render
      popupIcon={<DropdownThickIcon />}
      renderInput={(params) => (
        <TextField
          startIcon={false}
          {...params}
          {...textFieldProps}
          InputProps={{
            ...params.InputProps,
            ...(textFieldProps ?? {})?.InputProps,
          }}
          inputProps={{
            ...(textFieldProps ?? {})?.inputProps,
            ...params.inputProps,
          }}
        />
      )}
      slotProps={{
        paper: {
          sx: {
            borderBottomLeftRadius: "8px",
            borderBottomRightRadius: "8px",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            borderTop: "none",
            padding: "0 16px",
            boxShadow: (theme) =>
              `0 1px 0 ${theme.kinetic.v2.palette.rgb.grayTextLighter}, 
              -1px 0px 0 ${theme.kinetic.v2.palette.rgb.grayTextLighter}, 
              1px 0px 0 ${theme.kinetic.v2.palette.rgb.grayTextLighter}`,
          },
        },
        listbox: {
          sx: {
            padding: "8px 0 0 0",
          },
        },
        popper: {
          sx: {
            position: "absolute",
            bottom: "0 !important",
          },
        },
      }}
    />
  );
}
