import React from "react";
import { Box } from "../Box";
import { Tooltip } from "../Tooltip";

type SentimentBarProps = {
  sentiment: number | undefined;
};

export function SentimentBar({ sentiment = 0 }: SentimentBarProps) {
  const dullColors = ["#F9B1A7", "#FDECE9", "#FFFAE7", "#E9F7E6", "#BCE8B5"];
  const activeColors = ["#A72A19", "#EF3C24", "#FFDD56", "#63C851", "#167C05"];
  const labels = [
    "Very Negative",
    "Negative",
    "Neutral",
    "Positive",
    "Very Positive",
  ];

  const getActiveIndex = (value: number): number => {
    if (value >= 0.75) return 4;
    if (value >= 0.25) return 3;
    if (value >= -0.25) return 2;
    if (value >= -0.75) return 1;

    return 0;
  };

  const activeIndex = getActiveIndex(sentiment);

  return (
    <Box style={{ position: "relative", width: "100%", paddingBottom: "24px" }}>
      <Box
        style={{
          display: "flex",
          height: "14px",
          width: "100%",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        {dullColors.map((color, index) => (
          <Tooltip
            content={labels[index]}
            key={color}
            title={labels[index]}
            arrow
          >
            <Box
              style={{
                flex: 1,
                backgroundColor:
                  index === activeIndex
                    ? activeColors[index]
                    : dullColors[index],
              }}
            />
          </Tooltip>
        ))}
      </Box>
      <Box
        style={{
          position: "absolute",
          left: `${activeIndex * 20 + 10}%`,
          top: "-12px",
          transform: "translateX(-50%)",
        }}
      >
        <TriangleSvg />
        <Box
          style={{
            position: "absolute",
            width: "max-content",
            transform: "translateX(-50%)",
            color: "#252524",
            fontSize: "14px",
            marginTop: "6px",
            marginLeft: activeIndex === 0 ? "15px" : "10px",
            textAlign: "center",
          }}
        >
          {labels[activeIndex]}
        </Box>
      </Box>
    </Box>
  );
}

function TriangleSvg() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 8L19 8L12 19L5 8Z" fill="#252524" />
    </svg>
  );
}
