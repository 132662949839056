"use client";

import React, { useEffect, useId, useMemo, useState } from "react";
import { CardProps } from "../BaseCard";
import { FileCardWrapper } from "./styles";
import { Dialog, DialogHeader, DialogContent } from "../../../dialog";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocumentProxy, PageViewport } from "pdfjs-dist";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { asyncMap } from "@wojtekmaj/async-array-utils";
import { CircularProgress } from "../../../CircularProgress";

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.js`;

export type FileCardProps = CardProps & {
  title?: string;
  url?: string;
};

function Row({
  index,
  style,
  data,
}: {
  index: number;
  style: React.CSSProperties;
  data: {
    height: number;
    width: number;
  };
}) {
  return (
    <div style={style}>
      <Page
        width={data.width}
        height={data.height}
        pageIndex={index}
        renderAnnotationLayer={false}
        renderTextLayer={false}
      />
    </div>
  );
}

export function FileCard({ url = "", onCardActive }: FileCardProps) {
  const [pdf, setPdf] = useState<PDFDocumentProxy | null>(null);
  const [pageViewports, setPageViewports] = useState<PageViewport[] | null>(
    null
  );
  const [open, setOpen] = useState(false);

  useEffect(() => {
    onCardActive?.();
    async function getViewport() {
      setPageViewports(null);

      if (!pdf) {
        return;
      }

      const pageNumbers = Array.from(new Array(pdf.numPages)).map(
        (_, index) => index + 1
      );

      const nextPageViewports = await asyncMap(pageNumbers, (pageNumber) =>
        pdf.getPage(pageNumber).then((page) => page.getViewport({ scale: 1 }))
      );

      setPageViewports(nextPageViewports);
    }

    void getViewport();
  }, [pdf, open, onCardActive]);

  function onDocumentLoadSuccess(nextPdf: PDFDocumentProxy) {
    setPdf(nextPdf);
  }

  const PAGE_MARGIN = 20;

  function getPageHeight(pageIndex: number) {
    if (!pageViewports) {
      throw new Error("getPageHeight() called too early");
    }

    const pageViewport = pageViewports[pageIndex];
    const scale = 600 / pageViewport.width;
    const actualHeight = pageViewport.height * scale;

    return actualHeight + PAGE_MARGIN;
  }

  function getDialogPageHeight(pageIndex: number, containerWidth: number) {
    if (!pageViewports) {
      throw new Error("getDialogPageHeight() called too early");
    }

    const pageViewport = pageViewports[pageIndex];
    const scale = containerWidth / pageViewport.width;
    const actualHeight = pageViewport.height * scale;

    return actualHeight + PAGE_MARGIN;
  }

  const options = useMemo(
    () => ({
      cMapUrl: "cmaps/",
      cMapPacked: true,
      standardFontDataUrl: "standard_fonts/",
    }),
    []
  );

  const handleDocClick = () => {
    setOpen(true);
  };

  return (
    <FileCardWrapper>
      <AutoSizer style={{ height: "100%", width: "100%" }}>
        {({ height, width }) => {
          return (
            <Document
              file={url}
              onLoadSuccess={onDocumentLoadSuccess}
              onClick={handleDocClick}
            >
              {pdf && pageViewports && (
                <List
                  style={{ overflowX: "hidden" }}
                  width={width}
                  height={height}
                  estimatedItemSize={800}
                  itemCount={pdf.numPages}
                  itemSize={getPageHeight}
                  itemData={{ height: 800, width: 600 }}
                >
                  {Row}
                </List>
              )}
            </Document>
          );
        }}
      </AutoSizer>

      <Dialog
        open={open}
        maxWidth="lg"
        aria-labelledby="PDF"
        fullScreen
        keepMounted
      >
        <DialogHeader id={useId()} onClose={() => setOpen(false)}>
          <span>PDF</span>
        </DialogHeader>
        <DialogContent>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              backgroundColor: "#e4e4e4",
              height: "100%",
              flex: 1,
            }}
          >
            <AutoSizer style={{ height: "100%", width: "100%" }}>
              {({ height, width }) => {
                return (
                  <Document
                    loading={<CircularProgress />}
                    file={url}
                    options={options}
                  >
                    {pdf && pageViewports && (
                      <List
                        style={{ overflowX: "hidden" }}
                        width={width}
                        height={height}
                        estimatedItemSize={800}
                        itemCount={pdf.numPages}
                        itemSize={(index) => getDialogPageHeight(index, width)}
                        itemData={{ height: 800, width }}
                      >
                        {Row}
                      </List>
                    )}
                  </Document>
                );
              }}
            </AutoSizer>
          </div>
        </DialogContent>
      </Dialog>
    </FileCardWrapper>
  );
}
