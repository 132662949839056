import {
  ApolloClient,
  FetchResult,
  NextLink,
  NormalizedCacheObject,
  Observable,
  Operation,
} from "@apollo/client";
import { serialize, parse } from "cookie";
import { encrypt } from "./encryption";
import {
  AuthRefreshDocument,
  AuthRefreshMutation,
  AuthRefreshMutationVariables,
  AuthenticationSource,
} from "@gql/generated/graphql";
import { getRoute } from "@config/routes";

export function storeCompanyId(companyId: string) {
  const companyIdCookie = serialize("companyId", companyId, {
    domain: window.location.hostname,
    path: "/",
    maxAge: 60 * 60 * 24 * 365,
    secure: true,
    sameSite: "lax",
  });
  document.cookie = companyIdCookie;
}

/**
 * As it is a cookie it is present on the server, just not under "document.cookie"
 */
export function getCompanyId(): string | null {
  if (typeof document === "undefined") {
    return null;
  }
  const { companyId } = parse(document.cookie);

  if (companyId === "undefined") {
    return null;
  }

  return companyId || null;
}

/**
 *
 * @param companyIds
 */
export function storeCompanyIds(companyIds: string[] | "ALL") {
  if (typeof window === "undefined") return;

  let content;
  if (typeof companyIds === "string") {
    content = companyIds;
  } else {
    content = JSON.stringify(companyIds);
  }

  const companyIdsCookie = serialize("companyIds", encrypt(content), {
    domain: window.location.hostname,
    path: "/",
    maxAge: 60 * 60 * 24 * 365,
    secure: true,
    sameSite: "lax",
  });
  document.cookie = companyIdsCookie;
}

/**
 * As it is a cookie it is present on the server, just not under "document.cookie"
 */
export function destroyCompanyIds() {
  if (typeof window === "undefined") return;

  if (typeof document === "undefined") {
    return null;
  }
  const companyIdsCookie = serialize("companyIds", "", {
    domain: window.location.hostname,
    path: "/",
    maxAge: 0,
  });
  const companyExternalIdCookie = serialize("companyExternalId", "", {
    domain: window.location.hostname,
    path: "/",
    maxAge: 0,
  });
  const companyProfileIdCookie = serialize("companyProfileId", "", {
    domain: window.location.hostname,
    path: "/",
    maxAge: 0,
  });

  document.cookie = companyIdsCookie;
  document.cookie = companyExternalIdCookie;
  document.cookie = companyProfileIdCookie;
}

/**
 * As it is a cookie it is present on the server, just not under "document.cookie"
 */
export function destroyCompanyId() {
  if (typeof document === "undefined") {
    return null;
  }
  const companyIdCookie = serialize("companyId", "", {
    domain: window.location.hostname,
    path: "/",
    maxAge: 0,
  });
  document.cookie = companyIdCookie;
}

export const fetchNewTokenByRefreshToken = async (
  client: ApolloClient<NormalizedCacheObject>
) => {
  const { data } = await client.mutate<
    AuthRefreshMutation,
    AuthRefreshMutationVariables
  >({
    mutation: AuthRefreshDocument,
    variables: {
      source: AuthenticationSource.Admin,
    },
  });

  return data?.authRefresh ?? false;
};

let refreshPromise: Promise<void> | null = null;

export function fetchNewTokenAndRetry(
  apolloClient: ApolloClient<NormalizedCacheObject>,
  operation: Operation,
  forward: NextLink
) {
  return new Observable<FetchResult>((observer) => {
    const fetchNewTokenSuccess = () => {
      const subscriber = {
        next: observer.next.bind(observer),
        error: observer.error.bind(observer),
        complete: observer.complete.bind(observer),
      };

      return forward(operation).subscribe(subscriber);
    };

    const fetchNewTokenError = (error: unknown) => {
      window.location.replace(getRoute("login"));

      observer.error(error);
      console.error("Failed to refresh tokens");
      console.error(error);
    };

    if (!refreshPromise) {
      refreshPromise = fetchNewTokenByRefreshToken(apolloClient)
        .then(() => {
          refreshPromise = null;
          fetchNewTokenSuccess();
        })
        .catch((error) => {
          refreshPromise = null;
          fetchNewTokenError(error);
        });
    } else {
      refreshPromise.then(fetchNewTokenSuccess).catch(fetchNewTokenError);
    }
  });
}
