import * as React from "react";
import { ClearIcon, CheckIcon } from "../../../../icons";
import { Grid } from "../Grid";
import { Chip } from "../Chip";
import { BSPBody2 } from "../BSPBody2";

export type Props = {
  allowedKeywordsMatched?: Array<string>;
  allowedKeywordsIncludedNotMatched?: Array<string>;
  incorrectKeywordsMatched?: Array<string>;
  incorrectKeywordsNotMatched?: Array<string>;
  incorrectKeywordsStr?: string;
  expectedKeywordsStr?: string;
};

export function KeywordsAiAnalysis({
  allowedKeywordsMatched,
  allowedKeywordsIncludedNotMatched,
  incorrectKeywordsMatched,
  incorrectKeywordsNotMatched,
  incorrectKeywordsStr = "Incorrect Keywords",
  expectedKeywordsStr = "Expected keywords",
}: Props) {
  return (
    <>
      {(allowedKeywordsMatched?.length !== 0 ||
        allowedKeywordsIncludedNotMatched?.length !== 0) && (
        <Grid container spacing={1} gap={1}>
          <Grid container my={2}>
            <BSPBody2>{`${expectedKeywordsStr}:`}</BSPBody2>
          </Grid>
          {allowedKeywordsMatched?.map((item) => (
            <Grid key={item}>
              <Chip
                label={item}
                variant="outlined"
                color={"success"}
                icon={<CheckIcon color={"success"} />}
              />
            </Grid>
          ))}
          {allowedKeywordsIncludedNotMatched?.map((item) => (
            <Grid key={item}>
              <Chip key={item} label={item} variant="outlined" />
            </Grid>
          ))}
        </Grid>
      )}
      {(incorrectKeywordsMatched?.length !== 0 ||
        incorrectKeywordsNotMatched?.length !== 0) && (
        <Grid container spacing={1} gap={1}>
          <Grid container mt={4} mb={2}>
            <BSPBody2>{`${incorrectKeywordsStr}:`}</BSPBody2>
          </Grid>
          {incorrectKeywordsMatched?.map((item) => (
            <Grid key={item}>
              <Chip
                key={item}
                label={item}
                variant="outlined"
                color={"error"}
                icon={<ClearIcon color={"error"} />}
              />
            </Grid>
          ))}
          {incorrectKeywordsNotMatched?.map((item: string) => (
            <Grid key={item}>
              <Chip key={item} label={item} variant="outlined" />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
}
