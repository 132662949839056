import { styled } from "@mui/material/styles";
import { BSPBody1 } from "../BSPBody1";

export const ArticulationSlider = styled("div")<{
  progress: number;
  gradientType?: "sine" | "linear";
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  height: 5px;
  background: ${({ gradientType }) =>
    gradientType === "linear"
      ? // From red to green
        "linear-gradient(90deg, #F9B1A7 0%, #FFEB9F 50.6%, #BCE8B5 101.19%)"
      : // from red to green to red again like a wave
        "linear-gradient(90deg, #F9B1A7 0%, #FFEB9F 35.42%, #BCE8B5 51.1%, #FFEB9F 66.79%, #F9B1A7 101.19%)"};

  position: relative;

  & #thumb {
    width: 18px;
    height: 12px;
    flex-shrink: 0;
    border-radius: 8px;
    position: absolute;
    background: var(--Primary-Baseline, #134ee7);
    left: ${({ progress }) => progress}%;
  }

  & #duration {
    color: var(--Primary-Baseline, #134ee7);
    text-align: center;

    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 150% */
    letter-spacing: 0.24px;

    position: absolute;
    top: 10px;
    left: ${({ progress }) => progress}%;
  }
`;

export const ArticulationLabel = styled(BSPBody1)<{ bold?: boolean }>`
  font-weight: ${({ bold }) => (bold ? 600 : "normal")};
`;

export const DurationLabel = styled(BSPBody1)<{
  $visibility?: string;
}>`
  color: var(--Neutral-900-tint, #959592);
  text-align: center;

  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: 0.24px;
  visibility: ${({ $visibility }) => $visibility};
`;
