import { css, styled } from "@mui/material/styles";
import {
  DialogHeader as BaseDialogHeader,
  DialogContent as BaseDialogContent,
  DialogActions as BaseDialogActions,
} from "../../../../../dialog";
import { Button } from "../../../../../Button";
import { TextField } from "../../../../../TextField";
import { Radio as BaseRadio } from "../../../../../Radio";
import { Checkbox as BaseCheckbox } from "../../../../../Checkbox";
import { Grid } from "../../../../../Grid";
import { BSPBody1 } from "../../../../../BSPBody1";
import { BSPBody2 } from "../../../../../BSPBody2";
import { withTransientProps } from "../../../../../../../lib/emotion";

export const Container = styled("div")`
  flex: 1;
  max-width: 600px;
  height: 100%;
`;

export const DialogHeader = styled(BaseDialogHeader)`
  color: ${({ theme }) => theme.kinetic.v2.palette.rgb.grayTextLighter};
`;

export const DialogContent = styled(BaseDialogContent)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DialogActions = styled(BaseDialogActions, withTransientProps)<{
  $isSubmitted: boolean;
}>`
  display: flex;
  justify-content: center;

  @media (max-width: 576px) {
    flex-direction: ${(prop) => (prop.$isSubmitted ? "column" : "row")};
    padding-inline: 20px;
    padding-block: 12px;
  }
`;

export const InnerDialogActions = styled(Container)`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: end;
  width: 100%;
`;

export const SubmitButton = styled(Button)`
  height: 44px;
  width: fit-content;
  @media (max-width: 576px) {
    width: 100%;
  }
`;

export const ResultRow = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const text = css`
  font-size: 20px;
  line-height: 32px;
`;

export const ResultText = styled(BSPBody1)`
  ${text}
  font-weight: 700;
`;

export const PromptContent = styled(BSPBody1)`
  font-size: 24px;
  line-height: 36px;
  color: ${({ theme }) =>
    theme.kinetic.v3.palette.chroma.neutralshade[500].css()};
  font-weight: 700;
  display: block;
  padding-bottom: 20px;
  white-space: pre-line;
`;

type ChoiceRadioProps = { $isImage: boolean; $isReview?: boolean };
type ChoiceGridProps = { $isReview?: boolean };

export const ChoiceRadio = styled(
  BaseRadio,
  withTransientProps
)<ChoiceRadioProps>`
  margin-right: 0;
  padding-bottom: 14px;

  .MuiFormControlLabel-label {
    color: ${({ theme }) =>
      theme.kinetic.v3.palette.chroma.neutralshade[500].css()};
  }

  .Mui-checked + .MuiFormControlLabel-label {
    color: ${({ theme }) =>
      theme.kinetic.v3.palette.chroma.primarybaseline.css()} !important;
  }

  background-color: ${({ theme, $isReview }) =>
    $isReview
      ? theme.kinetic.v3.palette.chroma.positivetint[300].css()
      : "inherit"};

  ${({ $isImage }) =>
    $isImage &&
    css`
      align-items: flex-start;
    `}
`;

export const StyledChoiseGrid = styled(
  Grid,
  withTransientProps
)<ChoiceGridProps>`
  margin-bottom: 11px;
  padding: 0 10px;
  background-color: ${({ theme, $isReview }) =>
    $isReview
      ? theme.kinetic.v3.palette.chroma.positivetint[300].css()
      : "inherit"};
`;

export const ChoiceCheckbox = styled(
  BaseCheckbox,
  withTransientProps
)<ChoiceRadioProps>`
  margin-right: 0;
  padding-bottom: 12px;

  .MuiFormControlLabel-label {
    color: ${({ theme }) =>
      theme.kinetic.v3.palette.chroma.neutralshade[500].css()};
  }

  .Mui-checked + .MuiFormControlLabel-label {
    color: ${({ theme }) =>
      theme.kinetic.v3.palette.chroma.primarybaseline.css()} !important;
  }

  background-color: ${({ theme, $isReview }) =>
    $isReview
      ? theme.kinetic.v3.palette.chroma.positivetint[300].css()
      : "inherit"};

  ${({ $isImage }) =>
    $isImage &&
    css`
      align-items: flex-start;
    `}
`;

export const ChoiceImage = styled("img")`
  width: 100%;
  max-height: 400px;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  user-select: none;
  user-drag: none;
  padding-right: 20px;
  padding-top: 20px;
`;

type InputProps = { $state: "valid" | "inactive" | "limit" };

export const TextArea = styled(TextField)<InputProps>`
  width: 100%;
  min-height: 236px;
  margin-bottom: 12px;

  border-color: ${({ theme, $state }) => {
    if ($state === "inactive") {
      return theme.kinetic.v2.palette.rgb.grayUiLight;
    }

    return theme.kinetic.v2.palette.rgb.grayUiDarker;
  }} !important;
`;

export const CharacterCount = styled(BSPBody2, withTransientProps)<InputProps>`
  color: ${({ theme, $state }) => {
    if ($state === "inactive") {
      return theme.kinetic.v2.palette.rgb.grayUiDarker;
    }

    if ($state === "limit") {
      return theme.kinetic.v2.palette.rgb.negative;
    }

    return theme.kinetic.v2.palette.rgb.grayTextDark;
  }};
`;
