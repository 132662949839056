import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

export const FileCardWrapper = styled("div")`
  height: calc(100% - 20px);
  cursor: zoom-in;
  width: 600px;
  max-width: 600px;
  margin-top: 20px;
  position: relative;

  @supports (-webkit-touch-callout: none) {
    overflow-y: auto;
    height: calc(100vh - 200px);
  }

  ${({ theme }) => theme.breakpoint.maxWidth.xs`
    width: 100%;
  `};

  .react-pdf__Page {
    margin-bottom: 20px;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
  }
`;

export const ControlBar = styled("div", {
  label: "ControlBar",
  name: "ControlBar",
})`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  height: 48px;
  width: 100%;
`;

export const PaginationWrapper = styled("div", {
  label: "PaginationWrapper",
  name: "PaginationWrapper",
})`
  margin-top: 20px;
  width: 100%;
  height: 550px;

  @supports (-webkit-touch-callout: none) {
    width: 100% !important;
  }

  ${({ theme }) => theme.breakpoint.minWidth.xs`
    width: 500px;
  `};

  ${({ theme }) => theme.breakpoint.minWidth.sm`
    width: 800px;
  `};

  ${({ theme }) => theme.breakpoint.minWidth.md`
    width: 1054px;
  `};

  @media (max-height: 750px) {
    height: calc(100vh - 200px);
  }

  background-color: #e4e4e4;
`;

export const DocumentWrapper = styled("div", {
  label: "DocumentWrapper",
  name: "DocumentWrapper",
})`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: start;
  height: 100%;
  background-color: white;
  width: 100%;

  .react-pdf__Document {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .react-pdf__Page {
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .react-pdf_message {
    padding: 20px;
    color: white;
  }
`;

type IconProps = {
  onClick?: () => void;
};

export function ZoomInIcon({ onClick }: IconProps) {
  return (
    <svg
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_90_41)">
        <path
          d="M15.4351 14.0629H14.7124L14.4563 13.8159C15.3528 12.773 15.8925 11.4191 15.8925 9.94625C15.8925 6.66209 13.2304 4 9.94625 4C6.66209 4 4 6.66209 4 9.94625C4 13.2304 6.66209 15.8925 9.94625 15.8925C11.4191 15.8925 12.773 15.3528 13.8159 14.4563L14.0629 14.7124V15.4351L18.6369 20L20 18.6369L15.4351 14.0629ZM9.94625 14.0629C7.66838 14.0629 5.82962 12.2241 5.82962 9.94625C5.82962 7.66838 7.66838 5.82962 9.94625 5.82962C12.2241 5.82962 14.0629 7.66838 14.0629 9.94625C14.0629 12.2241 12.2241 14.0629 9.94625 14.0629Z"
          fill="#313130"
        />
        <path
          d="M10.7714 7H9.22857V9.22857H7V10.7714H9.22857V13H10.7714V10.7714H13V9.22857H10.7714V7Z"
          fill="#313130"
        />
      </g>
      <defs>
        <clipPath id="clip0_90_41">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ZoomOutIcon({ onClick }: IconProps) {
  return (
    <svg
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_90_46)">
        <path
          d="M15.4351 14.0629H14.7124L14.4563 13.8159C15.3528 12.773 15.8925 11.4191 15.8925 9.94625C15.8925 6.66209 13.2304 4 9.94625 4C6.66209 4 4 6.66209 4 9.94625C4 13.2304 6.66209 15.8925 9.94625 15.8925C11.4191 15.8925 12.773 15.3528 13.8159 14.4563L14.0629 14.7124V15.4351L18.6369 20L20 18.6369L15.4351 14.0629ZM9.94625 14.0629C7.66838 14.0629 5.82962 12.2241 5.82962 9.94625C5.82962 7.66838 7.66838 5.82962 9.94625 5.82962C12.2241 5.82962 14.0629 7.66838 14.0629 9.94625C14.0629 12.2241 12.2241 14.0629 9.94625 14.0629Z"
          fill="#313130"
        />
        <path
          d="M10.7714 7H9.22852V9.22857V10.7714V13H10.7714V10.7714V9.22857V7Z"
          fill="#313130"
        />
      </g>
      <defs>
        <clipPath id="clip0_90_46">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ExpandMoreIcon({ onClick }: IconProps) {
  return (
    <svg
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_90_25)">
        <path
          d="M7 14H5V19H10V17H7V14ZM5 10H7V7H10V5H5V10ZM17 17H14V19H19V14H17V17ZM14 5V7H17V10H19V5H14Z"
          fill="#313130"
        />
      </g>
      <defs>
        <clipPath id="clip0_90_25">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export const PageNumber = styled("input", {
  label: "PageNumber",
  name: "PageNumber",
})`
  text-align: center;
  margin-right: 10px;
  border-radius: 4px;
  border: 1px solid #d7d7d6;
  background-color: #fafaf7;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BaseFlex = styled(Box, {
  label: "BaseFlex",
  name: "BaseFlex",
})`
  display: flex;
  align-items: center;
  gap: 16px;

  svg {
    cursor: pointer;
  }
`;
