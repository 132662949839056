import React, { useId, useMemo, useState } from "react";

import { BaseCard, CardProps } from "../BaseCard";
import { pdfjs, Document, Page } from "react-pdf";
import { CircularProgress } from "../../../CircularProgress";
import { Dialog, DialogHeader, DialogContent } from "../../../dialog";
import {
  BaseFlex,
  ControlBar,
  ExpandMoreIcon,
  PageNumber,
  PaginationWrapper,
  ZoomInIcon,
  ZoomOutIcon,
} from "./styles";

import { useResizeDetector } from "react-resize-detector";

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

import { Box } from "@mui/material";
import { ChevronLeftIcon, ChevronRightIcon } from "../../../../../../icons";

export type PaginationProps = CardProps & {
  title?: string;
  url?: string;
};

pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";

export function Pagination({ url, onCardActive }: PaginationProps) {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [isVertical, setIsVertical] = useState(false);
  const [pageNumberInput, setPageNumberInput] = useState(pageNumber);
  const [isLoading, setIsLoading] = useState(true);

  const [open, setOpen] = useState(false);

  const [scale, setScale] = useState(1);

  const { width = 0, height = 0, ref } = useResizeDetector();

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setIsLoading(true);
      setPageNumber(pageNumber + 1);
      setPageNumberInput(pageNumber + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pageNumber > 1) {
      setIsLoading(true);
      setPageNumber(pageNumber - 1);
      setPageNumberInput(pageNumber - 1);
    }
  };

  const handleZoomIn = () => {
    setScale(scale + 0.1);
  };

  const handleZoomOut = () => {
    setScale(scale - 0.1);
  };

  const options = useMemo(
    () => ({
      cMapUrl: "cmaps/",
      cMapPacked: true,
      standardFontDataUrl: "standard_fonts/",
    }),
    []
  );

  return (
    <PaginationWrapper ref={open ? undefined : ref}>
      <BaseCard
        $fitContent={isLoading ? undefined : true}
        isActive={true}
        onCardActive={onCardActive}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Document
            onLoadSuccess={async (pdf) => {
              setIsLoading(false);
              const page = await pdf.getPage(1);
              const viewport = page.getViewport({ scale: 1 });
              setIsVertical(viewport.height > viewport.width);
              setNumPages(pdf.numPages);
            }}
            loading={<CircularProgress />}
            file={url}
            options={options}
          >
            {/* according to design, the pdf should fit either width or height, depending on the orientation. */}
            <Page
              pageNumber={pageNumber}
              width={isVertical ? undefined : width - 160}
              height={isVertical ? height : undefined}
              scale={scale}
              loading={<CircularProgress />}
              onLoadSuccess={() => setIsLoading(false)}
            />
          </Document>
        </div>
      </BaseCard>{" "}
      <ControlBar>
        {/* not supporting zoom at the moment */}
        <BaseFlex
          style={{
            visibility: "hidden",
          }}
        >
          <ZoomInIcon onClick={handleZoomIn} />
          <ZoomOutIcon onClick={handleZoomOut} />
        </BaseFlex>

        <BaseFlex>
          <PageNumber
            onChange={(e) => {
              // Only allow numbers and empty string
              const value = e.target.value.replace(/[^\d]/g, "");
              setPageNumberInput(value as any);
            }}
            onBlur={(e) => {
              const newPage = parseInt(e.target.value) || 1;
              const validPage = Math.min(Math.max(1, newPage), numPages);
              setPageNumber(validPage);
              setPageNumberInput(validPage);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.currentTarget.blur();
              }
            }}
            value={pageNumberInput}
          />
          <Box>{numPages}</Box>
          <BaseFlex>
            <ChevronLeftIcon
              style={{
                color: pageNumber === 1 ? "#afafaf" : "inherit",
              }}
              onClick={handlePreviousPage}
            />
            <ChevronRightIcon
              style={{
                color: pageNumber === numPages ? "#afafaf" : "inherit",
              }}
              onClick={handleNextPage}
            />
          </BaseFlex>
        </BaseFlex>
        <BaseFlex>
          {/* not supporting refresh at the moment */}
          {/* <RefreshIcon /> */}
          <ExpandMoreIcon
            onClick={() => {
              const screenWidth = window.innerWidth;
              setIsVertical(screenWidth < 768);
              setScale(screenWidth < 768 ? 1.5 : 1);
              setOpen(true);
            }}
          />
        </BaseFlex>
      </ControlBar>
      <Dialog open={open} maxWidth="lg" aria-labelledby="PDF" fullScreen>
        <DialogHeader id={useId()} onClose={() => setOpen(false)}>
          <Box display={"flex"} alignItems={"center"} gap={3}>
            <ChevronLeftIcon
              style={{
                color: pageNumber === 1 ? "#afafaf" : "inherit",
              }}
              onClick={handlePreviousPage}
            />
            <span>PDF</span>
            <ChevronRightIcon
              style={{
                color: pageNumber === numPages ? "#afafaf" : "inherit",
              }}
              onClick={handleNextPage}
            />
          </Box>
        </DialogHeader>
        <DialogContent>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              backgroundColor: "#e4e4e4",
            }}
            ref={open ? ref : undefined}
          >
            <Document
              onLoadSuccess={async (pdf) => {
                const page = await pdf.getPage(1);
                const viewport = page.getViewport({ scale: 1 });
                setIsVertical(viewport.height > viewport.width);
                setNumPages(pdf.numPages);
              }}
              loading={<CircularProgress />}
              file={url}
              options={options}
            >
              <Page
                pageNumber={pageNumber}
                scale={1}
                width={window.innerWidth - 160}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                devicePixelRatio={1}
              />
            </Document>
          </div>
        </DialogContent>
      </Dialog>
    </PaginationWrapper>
  );
}
