import { BaseCard } from "./templates/BaseCard";
import { CompletionCard } from "./templates/CompletionCard";
import { ErrorCard } from "./templates/ErrorCard";
import { FileCard } from "./templates/FileCard";
import { Pagination } from "./templates/FileCard/pagination";
import { FlashCard } from "./templates/FlashCard";
import { FormCard } from "./templates/FormCard";
import { LinkoutCard } from "./templates/LinkoutCard";
import { ResetCard } from "./templates/ResetCard";
import { VideoCard } from "./templates/VideoCard";

// TODO: Remove these exports after completing full CC refactor
export * from "./templates/BaseCard";
export * from "./templates/CompletionCard";
export * from "./templates/ErrorCard";
export * from "./templates/FileCard";
export * from "./templates/FlashCard";
export * from "./templates/FormCard";
export * from "./templates/LinkoutCard";
export * from "./templates/ResetCard";
export * from "./templates/VideoCard";
export * from "./types";

export const Card = {
  BaseCard,
  CompletionCard,
  ErrorCard,
  FileCard,
  Pagination,
  FlashCard,
  FormCard,
  LinkoutCard,
  ResetCard,
  VideoCard,
};
