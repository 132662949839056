import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import * as tf from "@tensorflow/tfjs-core";
import "@tensorflow/tfjs-converter";
import * as tfjsWasm from "@tensorflow/tfjs-backend-wasm";
import * as selfieSegmentation from "@mediapipe/selfie_segmentation";
import {
  BodySegmenter,
  createSegmenter,
  SupportedModels,
} from "@tensorflow-models/body-segmentation";

import { Localizations, MenuOptionType, ShootType, WebcamView } from "./view";
import {
  drawBlackBackground,
  drawPlain,
  drawPlainDeviceMedia,
  drawWithBackground,
  drawWithBlur,
  waitForEvent,
} from "./helpers";
import deviceDetect from "ismobilejs";
import { typewriterTrack } from "../../../lib/events";
import { Effect, EffectType } from "./components/VisualEffectsModal";
import { SelectedDevices } from "./components/SettingsModal";
import { OptionType } from "./components/OptionsMenu";
import {
  AppSource,
  BackgroundType,
  ButtonType,
  ResponseType,
} from "../../../../typewriter/segment";

tfjsWasm.setWasmPaths(
  `https://cdn.jsdelivr.net/npm/@tensorflow/tfjs-backend-wasm@${tfjsWasm.version_wasm}/dist/`
);

export type Interjection = {
  start: number;
  end: number;
  message: string;
  author: string;
};

type FacingMode = "user" | "environment";

type Options = Record<
  | Exclude<
      OptionType,
      OptionType.BACKGROUNDS | OptionType.SETTINGS | OptionType.UPLOAD_FILE
    >
  | MenuOptionType,
  boolean
>;

type VideoSource = "frontCamera" | "rearCamera";

type Props = {
  // TODO: Replace these two props by mode = 'video' | 'photo'
  /** Whether the camera should allow photos or not. Do not enabled this option if isVideoEnabled is true */
  isPhotoEnabled: boolean;
  /** Whether the camera should allow videos or not. Do not enabled this option if isPhotoEnabled is true */
  isVideoEnabled: boolean;
  // TODO: We have to decide whether we want to handle events and errors
  // in core components or raise events to the parent apps. I think
  // core components should be agnostic of any logging system.
  // Leaving as is since we already have a Segment protocol created for CC
  trackingProps: {
    companyExternalId: string;
    profileExternalId: string;
    appSource: "admin" | "pwa";
  };
  localizations?: Localizations;
  onLoaded?: VoidFunction;
  onError?: (error: Error) => void;
  /* Event triggered during video recording every 1 second. Only available in video mode */
  onTimeUpdate?: (seconds: number) => void;
  /** Event triggered when count down starts after user clicks on record/shoot button */
  onCountDownStarts?: VoidFunction;
  /**
   * Event triggered when screen sharing ends.
   * It gets the current state of the options as first argument
   * in case it is needed to perform any action in callers
   */
  onScreenShareEnds?: (options: Options) => void;
  /** Event triggered when video recording starts after count down */
  onRecordStarts?: VoidFunction;
  /** Event triggered when video recording ends or image is taken */
  onRecordEnds: (file: File, source: VideoSource) => void;
  /** Event triggered when a file is selected from the file system */
  onFileUploaded: (file: File) => void;
};

type ExposedMethods = {
  onOptionClick: (type: OptionType | MenuOptionType) => Promise<boolean>;
};

const FPS = 60;
const COUNT_DOWN = 3;
const EDGE_BLUR_AMOUNT = 10;
const FLIP_USER_CAMERA_HORIZONTAL = true;
const USER_CAMERA_AS_POPUP = {
  x: 40,
  y: 40,
  width: 240,
  height: 135,
  borderRadius: 10,
};

const EXPORTED_IMAGE_FORMAT = "image/jpeg";
const EXPORTED_VIDEO_FORMAT = "video/webm";
const FALLBACK_EXPORTED_VIDEO_FORMAT = "video/mp4";

// Add this helper function at the top of the file
const debugLog = (message: string, data?: any) => {
  console.log(`[Webcam Debug] ${message}`, data || "");
};

const errorLog = (message: string, error?: any) => {
  console.error(`[Webcam Error] ${message}`, error || "");
};

export const Webcam = forwardRef<ExposedMethods, Props>(
  (
    {
      isPhotoEnabled,
      isVideoEnabled,
      trackingProps,
      localizations,
      onLoaded,
      onError,
      onTimeUpdate,
      onCountDownStarts,
      onScreenShareEnds,
      onRecordStarts,
      onRecordEnds,
      onFileUploaded,
    },
    ref
  ) => {
    debugLog("Initializing Webcam component");

    const isMobile = deviceDetect(window.navigator).phone;
    const audioContext = useMemo(() => new AudioContext(), []);
    const mediaStreamAudioDestinationNode = useMemo(
      () => new MediaStreamAudioDestinationNode(audioContext),
      [audioContext]
    );

    const mountedRef = useRef(true);
    const drawCanvasTimeoutRef = useRef<number | null>();
    const countDownIntervalRef = useRef<number | null>(null);
    const timerIntervalRef = useRef<number | null>(null);

    /**
     * Canvas containing the user and device media (screen share, camera and microfone)
     */
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    /**
     * The media stream of the user (camera and microfone)
     */
    const userMediaStreamRef = useRef<MediaStream | null>(null);

    /**
     * The media stream of the device (screen sharing)
     */
    const deviceMediaStreamRef = useRef<MediaStream | null>(null);

    /**
     * Media recorder is used to take the image from the canvas
     * and transform it in a video file. You can change the video format
     * by updating the constant EXPORTED_VIDEO_FORMAT
     */
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);

    const currentUserAudioSourceRef = useRef<MediaStreamAudioSourceNode | null>(
      null
    );
    const currentDeviceAudioSourceRef =
      useRef<MediaStreamAudioSourceNode | null>(null);

    const modelRef = useRef<BodySegmenter | null>(null);

    /**
     * We need this ref in order to handle the toggle behavior inside drawCanvas()
     * function since it stars an inifite loop and it loose the React context
     * and loose the states. To toggle the icons in the screen we use the "effects" state
     */
    const effectsRef = useRef<{
      camera: boolean;
      background: HTMLImageElement | false;
      blur: number;
      flipHorizontal: boolean;
    }>({
      camera: true,
      background: false,
      blur: 0,
      flipHorizontal: false,
    });

    const [videoDim, setVideoDim] = useState({ width: 0, height: 0 });
    const [isLoading, setIsLoading] = useState(false);
    const [hideInfinityMirrorWarning, setHideInfinityMirrorWarning] =
      useState(false);
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
    const [isVisualEffectsModalOpen, setIsVisualEffectsModalOpen] =
      useState(false);
    const [availableDevices, setAvailableDevices] = useState<MediaDeviceInfo[]>(
      []
    );
    const [selectedDevices, setSelectedDevices] = useState<SelectedDevices>({
      audioDevice: undefined,
      videoDevice: undefined,
    });

    const [countDown, setCountDown] = useState(COUNT_DOWN);
    const [hasShootStarted, setHasShootStarted] = useState<
      ShootType | undefined
    >();

    const [seconds, setSeconds] = useState(0);

    /**
     * These are the available menu options. This state is used to update
     * the icons in the view, just for visiblity purposes.
     */
    const [options, setOptions] = useState<Options>({
      mic: true,
      camera: true,
      presentation: false,
    });

    /**
     * This is the video element used to capture the user media stream provided
     * by MediaDevices
     */
    const userVideo = useRef(document.createElement("video"));
    userVideo.current.muted = true;
    userVideo.current.playsInline = true;

    /**
     * This is the video element used to capture the device media stream provided
     * by MediaDevices
     */
    const deviceVideo = useRef(document.createElement("video"));
    const isSharingScreenOrBrowserWindow = useRef(false);
    deviceVideo.current.muted = true;
    deviceVideo.current.playsInline = true;

    const drawCanvas = useCallback(async () => {
      if (drawCanvasTimeoutRef.current) {
        window.clearTimeout(drawCanvasTimeoutRef.current);
      }

      if (canvasRef.current) {
        let context: CanvasRenderingContext2D | null = null;

        if (deviceMediaStreamRef.current) {
          context = drawPlainDeviceMedia(
            deviceVideo.current,
            canvasRef.current
          );
        }

        if (
          effectsRef.current.camera &&
          !effectsRef.current.background &&
          !effectsRef.current.blur
        ) {
          drawPlain(userVideo.current, canvasRef.current, {
            flipHorizontal: effectsRef.current.flipHorizontal,
            asPopup: deviceMediaStreamRef.current
              ? {
                  previousCtx: context,
                  x: USER_CAMERA_AS_POPUP.x,
                  y: USER_CAMERA_AS_POPUP.y,
                  width: USER_CAMERA_AS_POPUP.width,
                  height: USER_CAMERA_AS_POPUP.height,
                  borderRadius: USER_CAMERA_AS_POPUP.borderRadius,
                }
              : undefined,
          });
        }

        if (
          effectsRef.current.camera &&
          effectsRef.current.blur &&
          modelRef.current
        ) {
          await drawWithBlur(
            modelRef.current,
            userVideo.current,
            canvasRef.current,
            {
              backgroundBlurAmount: effectsRef.current.blur,
              edgeBlurAmount: EDGE_BLUR_AMOUNT,
              flipHorizontal: effectsRef.current.flipHorizontal,
              asPopup:
                deviceMediaStreamRef.current && effectsRef.current.camera
                  ? {
                      previousCtx: context,
                      x: USER_CAMERA_AS_POPUP.x,
                      y: USER_CAMERA_AS_POPUP.y,
                      width: USER_CAMERA_AS_POPUP.width,
                      height: USER_CAMERA_AS_POPUP.height,
                      borderRadius: USER_CAMERA_AS_POPUP.borderRadius,
                    }
                  : undefined,
            }
          );
        }

        if (
          effectsRef.current.camera &&
          effectsRef.current.background &&
          modelRef.current
        ) {
          await drawWithBackground(
            modelRef.current,
            userVideo.current,
            canvasRef.current,
            {
              flipHorizontal: effectsRef.current.flipHorizontal,
              backgroundImage: effectsRef.current.background,
              asPopup: deviceMediaStreamRef.current
                ? {
                    previousCtx: context,
                    x: USER_CAMERA_AS_POPUP.x,
                    y: USER_CAMERA_AS_POPUP.y,
                    width: USER_CAMERA_AS_POPUP.width,
                    height: USER_CAMERA_AS_POPUP.height,
                    borderRadius: USER_CAMERA_AS_POPUP.borderRadius,
                  }
                : undefined,
            }
          );
        }

        if (!deviceMediaStreamRef.current && !effectsRef.current.camera) {
          drawBlackBackground(canvasRef.current);
        }
      }

      drawCanvasTimeoutRef.current = window.setTimeout(drawCanvas, 1000 / FPS);
    }, []);

    const clearCountDownAndReset = () => {
      debugLog("Clearing countdown and resetting");
      if (countDownIntervalRef.current) {
        window.clearInterval(countDownIntervalRef.current);
      }

      countDownIntervalRef.current = null;
      setCountDown(COUNT_DOWN);
    };

    const stopScreenShare = useCallback(() => {
      debugLog("Stopping screen share");
      if (currentDeviceAudioSourceRef.current) {
        try {
          currentDeviceAudioSourceRef.current.disconnect();
          debugLog("Disconnected device audio source");
        } catch (error) {
          errorLog("Error disconnecting device audio source:", error);
        }
        currentDeviceAudioSourceRef.current = null;
      }

      if (deviceMediaStreamRef.current) {
        debugLog("Stopping device media stream tracks");
        const tracks = deviceMediaStreamRef.current.getTracks();
        tracks.forEach((track) => {
          try {
            debugLog(
              `Stopping track: ${track.kind}, readyState: ${track.readyState}`
            );
            if (track.readyState === "live") {
              track.stop();
            }
          } catch (error) {
            errorLog("Error stopping device track:", error);
          }
        });
        deviceMediaStreamRef.current = null;
      }

      deviceVideo.current.srcObject = null;
      debugLog("Device video source cleared");

      setOptions((prevState) => {
        const result = { ...prevState, presentation: false };
        debugLog("Screen share options updated");
        onScreenShareEnds?.(result);

        return result;
      });

      setHideInfinityMirrorWarning(false);
    }, [onScreenShareEnds]);

    const stopStream = useCallback(() => {
      debugLog("Stopping stream");
      if (drawCanvasTimeoutRef.current) {
        window.clearTimeout(drawCanvasTimeoutRef.current);
        drawCanvasTimeoutRef.current = null;
        debugLog("Cleared draw canvas timeout");
      }

      if (userMediaStreamRef.current) {
        debugLog("Stopping user media stream tracks");
        const tracks = userMediaStreamRef.current.getTracks();
        tracks.forEach((track) => {
          try {
            debugLog(
              `Stopping track: ${track.kind}, readyState: ${track.readyState}`
            );
            if (track.readyState === "live") {
              track.stop();
            }
          } catch (error) {
            errorLog("Error stopping track:", error);
          }
        });
        userMediaStreamRef.current = null;
      }

      userVideo.current.srcObject = null;
      debugLog("User video source cleared");

      if (
        mediaRecorderRef.current?.state &&
        mediaRecorderRef.current.state !== "inactive"
      ) {
        try {
          debugLog(
            `Stopping media recorder, current state: ${mediaRecorderRef.current.state}`
          );
          mediaRecorderRef.current?.pause();
          mediaRecorderRef.current?.stop();
        } catch (error) {
          errorLog("Error stopping media recorder:", error);
        }
      }
      mediaRecorderRef.current = null;
      debugLog("Media recorder reference cleared");

      if (currentUserAudioSourceRef.current) {
        try {
          currentUserAudioSourceRef.current.disconnect();
          debugLog("Disconnected user audio source");
        } catch (error) {
          errorLog("Error disconnecting audio source:", error);
        }
        currentUserAudioSourceRef.current = null;
      }

      if (options.presentation) {
        debugLog("Stopping screen share as part of stream cleanup");
        stopScreenShare();
      }
    }, [options.presentation, stopScreenShare]);

    // Add logs to getUserMediaWithRetry
    const getUserMediaWithRetry = async (
      facingMode?: FacingMode,
      selectedDevices?: SelectedDevices,
      retryCount = 0
    ): Promise<MediaStream> => {
      debugLog(`getUserMediaWithRetry called, attempt ${retryCount + 1}`);

      // Define constraints with decreasing strictness based on retry count
      const getConstraints = (retry: number) => {
        debugLog(`Getting constraints for retry level ${retry}`);
        // Base audio constraints
        const audioConstraints = isVideoEnabled
          ? {
              echoCancellation: true,
              noiseSuppression: true,
              deviceId: selectedDevices?.audioDevice?.deviceId,
            }
          : false;

        // Video constraints with decreasing strictness
        let videoConstraints: MediaTrackConstraints = {};

        if (retry === 0) {
          debugLog("Using ideal constraints (first attempt)");
          // First attempt - use ideal values
          videoConstraints = {
            frameRate: { ideal: FPS },
            facingMode,
            deviceId: !isMobile
              ? selectedDevices?.videoDevice?.deviceId
              : undefined,
            aspectRatio: { ideal: 16 / 9 },
            noiseSuppression: true,
            width: isMobile ? { min: 240, ideal: 480 } : { ideal: 1920 },
            height: isMobile ? { min: 320, ideal: 720 } : { ideal: 1080 },
          };
        } else if (retry === 1) {
          debugLog("Using reduced constraints (second attempt)");
          // Second attempt - remove aspect ratio and frameRate requirements
          videoConstraints = {
            facingMode,
            deviceId: !isMobile
              ? selectedDevices?.videoDevice?.deviceId
              : undefined,
            width: isMobile ? { min: 240 } : { ideal: 1280 },
            height: isMobile ? { min: 320 } : { ideal: 720 },
          };
        } else {
          debugLog("Using minimal constraints (final attempt)");
          // Final attempt - minimal constraints
          videoConstraints = {
            facingMode,
            deviceId: !isMobile
              ? selectedDevices?.videoDevice?.deviceId
              : undefined,
          };
        }

        return { audio: audioConstraints, video: videoConstraints };
      };

      try {
        // Try with current constraints level
        const constraints = getConstraints(retryCount);
        debugLog(
          `Attempting to get user media (attempt ${retryCount + 1})`,
          constraints
        );

        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        debugLog("Successfully got media stream", {
          audioTracks: stream.getAudioTracks().length,
          videoTracks: stream.getVideoTracks().length,
        });

        return stream;
      } catch (error) {
        errorLog(`Error on attempt ${retryCount + 1}:`, error);

        // If we've tried all constraint levels, throw the error
        if (retryCount >= 2) {
          errorLog("All retry attempts failed, throwing error");
          throw error;
        }

        // Otherwise retry with less strict constraints
        debugLog(
          `Retrying with less strict constraints (attempt ${retryCount + 2})`
        );

        return getUserMediaWithRetry(
          facingMode,
          selectedDevices,
          retryCount + 1
        );
      }
    };

    // Add logs to startStream
    const startStream = useCallback(
      async (facingMode?: FacingMode, selectedDevices?: SelectedDevices) => {
        debugLog("Starting stream", { facingMode, selectedDevices });

        if (drawCanvasTimeoutRef.current) {
          window.clearTimeout(drawCanvasTimeoutRef.current);
          drawCanvasTimeoutRef.current = null;
          debugLog("Cleared draw canvas timeout");
        }

        if (userMediaStreamRef.current) {
          debugLog("Stopping existing user media stream tracks");
          const tracks = userMediaStreamRef.current.getTracks();
          tracks.forEach((track) => {
            try {
              debugLog(
                `Stopping track: ${track.kind}, readyState: ${track.readyState}`
              );
              if (track.readyState === "live") {
                track.stop();
              }
            } catch (error) {
              errorLog("Error stopping track:", error);
            }
          });
        }

        userVideo.current.pause();
        userVideo.current.srcObject = null;
        debugLog("User video paused and source cleared");

        try {
          // Use our retry mechanism instead of a single getUserMedia call
          debugLog("Calling getUserMediaWithRetry");
          const stream = await getUserMediaWithRetry(
            facingMode,
            selectedDevices
          );

          if (facingMode === "environment") {
            effectsRef.current.flipHorizontal = false;
            debugLog("Using environment camera, flip horizontal disabled");
          } else if (facingMode === "user") {
            effectsRef.current.flipHorizontal = FLIP_USER_CAMERA_HORIZONTAL;
            debugLog("Using user camera, flip horizontal enabled");
          }

          userMediaStreamRef.current = stream;
          userVideo.current.srcObject = stream;
          userVideo.current.load();
          debugLog("Stream assigned to video element and loaded");

          debugLog("Waiting for loadedmetadata event");
          await waitForEvent(userVideo.current, "loadedmetadata");
          debugLog("loadedmetadata event received");

          userVideo.current.width = userVideo.current.videoWidth;
          userVideo.current.height = userVideo.current.videoHeight;
          debugLog("Video dimensions set", {
            width: userVideo.current.videoWidth,
            height: userVideo.current.videoHeight,
          });

          if (!mountedRef.current) {
            debugLog("Component unmounted during stream start, aborting");

            return;
          }

          setVideoDim({
            width: userVideo.current.videoWidth,
            height: userVideo.current.videoHeight,
          });
          debugLog("Video dimensions state updated");

          void userVideo.current.play();
          debugLog("Video playback started");

          debugLog("Starting canvas drawing");
          await drawCanvas();
          debugLog("Canvas drawing started");
        } catch (error) {
          errorLog("Error trying to access camera:", error);
          // Propagate the error to be handled by the onError callback
          throw error;
        }
      },
      [drawCanvas, isMobile, isVideoEnabled]
    );

    const startScreenShare = useCallback(async () => {
      try {
        if (!navigator.mediaDevices.getDisplayMedia) {
          alert("Your device does not support screen sharing");

          return false;
        }

        const stream = await navigator.mediaDevices.getDisplayMedia({
          audio: {
            noiseSuppression: true,
            echoCancellation: true,
          },
          video: {
            frameRate: FPS,
            // Screen share on mobile is not allowed so we can confidently hardcode
            // these values here
            aspectRatio: 16 / 9,
            width: { ideal: 1920 },
            height: { ideal: 1080 },
          },
        });

        deviceVideo.current.srcObject = stream;
        deviceVideo.current.load();

        await waitForEvent(deviceVideo.current, "loadedmetadata");

        deviceVideo.current.width = deviceVideo.current.videoWidth;
        deviceVideo.current.height = deviceVideo.current.videoHeight;

        void deviceVideo.current.play();

        deviceMediaStreamRef.current = stream;

        // If display surface shared is different than a browser tab then we are sharing an entire monitor or window
        // TODO: Casting this to any to avoid Admin build issue that can't find displaySurface property
        // This is not a problem for now since we are not using this camera version there but we probably
        // need to upgrade NextJs versions to fix this
        isSharingScreenOrBrowserWindow.current =
          (stream.getVideoTracks()[0]?.getSettings() as unknown as any)
            .displaySurface !== "browser";

        // Not all the browsers and operaing systems support device audio capture
        // so we need to validate properly before doing the merge.
        // https://caniuse.com/mdn-api_mediadevices_getdisplaymedia_audio_capture_support
        if (stream.getAudioTracks().length) {
          const deviceSource = audioContext.createMediaStreamSource(
            deviceMediaStreamRef.current
          );
          const deviceGain = audioContext.createGain();
          deviceGain.gain.value = 0.8;
          deviceSource
            .connect(deviceGain)
            .connect(mediaStreamAudioDestinationNode);

          currentDeviceAudioSourceRef.current?.disconnect();
          currentDeviceAudioSourceRef.current = deviceSource;
        }

        stream.getVideoTracks()[0].onended = function () {
          stopScreenShare();

          typewriterTrack("recorderOptionClicked", {
            appSource: trackingProps.appSource as AppSource,
            responseType: isVideoEnabled
              ? ResponseType.Video
              : ResponseType.Image,
            buttonType: ButtonType.RecordScreenStopped,
          });
        };

        return true;
      } catch (error) {
        const err = error as Error;
        if (err.name === "NotAllowedError") {
          console.error(
            "You need to give permissions to the screen sharing to continue!"
          );
        } else {
          // NotAllowedError is not necessarily an unexpected error. It happens everytime
          // a user clicks on the Cancel button or don't allow screen share in Safari for example
          // We are tracking just unexpected errors
          typewriterTrack("webcamScreenShareFailed", {
            companyExternalId: trackingProps.companyExternalId,
            profileExternalId: trackingProps.profileExternalId,
            errorMessage: err.message,
            errorName: err.name,
          });

          onError?.(err);

          alert(
            "An unexpected error has occurred. Please contact customer support!"
          );
        }

        console.error(error);

        return false;
      }
    }, [
      audioContext,
      isVideoEnabled,
      mediaStreamAudioDestinationNode,
      onError,
      stopScreenShare,
      trackingProps.appSource,
      trackingProps.companyExternalId,
      trackingProps.profileExternalId,
    ]);

    const getDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();

      setAvailableDevices(devices);

      return devices;
    };

    useEffect(() => {
      const onDeviceChange = async () => {
        const devices = await getDevices();

        // We need to filter by groupId too because sometimes we can have more than one "default" device ID
        // in different groups.
        // This filter is done in order to remove a current selected device that is not available anymore.
        // E.g: If we are using the headphones microphone and we turn the device off, etc.
        const storageSelectedDevices = localStorage.getItem("selectedDevices");
        let storageSelectedDevicesJson: SelectedDevices | undefined;

        if (storageSelectedDevices) {
          try {
            storageSelectedDevicesJson = JSON.parse(storageSelectedDevices);
          } catch {
            console.warn("no settings saved");
          }
        }

        let selectedAudioDevice = devices.find(
          (device) =>
            device.deviceId ===
              storageSelectedDevicesJson?.audioDevice?.deviceId &&
            device.groupId === storageSelectedDevicesJson?.audioDevice?.groupId
        );

        if (!selectedAudioDevice) {
          selectedAudioDevice = devices.find(
            (device) =>
              device.deviceId === selectedDevices.audioDevice?.deviceId &&
              device.groupId === selectedDevices.audioDevice?.groupId
          );
        }

        let selectedVideoDevice = devices.find(
          (device) =>
            device.deviceId ===
              storageSelectedDevicesJson?.videoDevice?.deviceId &&
            device.groupId === storageSelectedDevicesJson?.videoDevice?.groupId
        );

        if (!selectedVideoDevice) {
          selectedVideoDevice = devices.find(
            (device) =>
              device.deviceId ===
                storageSelectedDevicesJson?.videoDevice?.deviceId &&
              device.groupId ===
                storageSelectedDevicesJson?.videoDevice?.groupId
          );
        }

        // If no audio device try selecting the default one. If no default, select the first available
        if (!selectedAudioDevice) {
          selectedAudioDevice = devices.find(
            (device) => device.deviceId === "default"
          );
          if (!selectedAudioDevice) {
            selectedAudioDevice = devices.filter(
              (device) => device.kind === "audioinput"
            )?.[0];
          }
        }

        // If no video device try selecting the default one. If no default, select the first available
        if (!selectedVideoDevice) {
          selectedVideoDevice = devices.find(
            (device) => device.deviceId === "default"
          );
          if (!selectedVideoDevice) {
            selectedVideoDevice = devices.filter(
              (device) => device.kind === "videoinput"
            )?.[0];
          }
        }

        if (!mountedRef.current) {
          return;
        }

        setSelectedDevices({
          audioDevice: selectedAudioDevice,
          videoDevice: selectedVideoDevice,
        });

        const currentFacingMode = userMediaStreamRef.current
          ?.getVideoTracks()[0]
          .getConstraints().facingMode as FacingMode;

        void startStream(currentFacingMode, {
          audioDevice: selectedAudioDevice,
          videoDevice: selectedVideoDevice,
        });
      };

      if (!navigator.mediaDevices || !navigator.mediaDevices.addEventListener) {
        return onError?.(new Error("Your browser does not support recording!"));
      }

      navigator.mediaDevices.addEventListener("devicechange", onDeviceChange);

      return () => {
        navigator.mediaDevices.removeEventListener(
          "devicechange",
          onDeviceChange
        );
      };
    }, [onError, selectedDevices, startStream]);

    const OrientationFunction = useCallback(async () => {
      setSelectedDevices({
        audioDevice: selectedDevices.audioDevice,
        videoDevice: undefined,
      });

      const currentFacingMode = userMediaStreamRef.current
        ?.getVideoTracks()[0]
        .getConstraints().facingMode as FacingMode;

      void startStream(currentFacingMode, selectedDevices);
      stopStream();
    }, [selectedDevices, startStream, stopStream]);

    const clearTimer = useCallback(() => {
      if (timerIntervalRef.current) {
        window.clearInterval(timerIntervalRef.current);
      }

      setSeconds(0);
      onTimeUpdate?.(0);

      timerIntervalRef.current = null;
    }, [onTimeUpdate]);

    useEffect(() => {
      window.addEventListener("orientationchange", OrientationFunction);

      return () =>
        window.removeEventListener("orientationchange", OrientationFunction);
    }, [OrientationFunction]);

    useEffect(() => {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        return onError?.(new Error("Your browser does not support recording!"));
      }

      const init = async () => {
        try {
          setIsLoading(true);

          // Lock screen in portrait mode for mobile devices
          // Still not fully supported
          // See https://developer.mozilla.org/en-US/docs/Web/API/ScreenOrientation/lock for more details
          try {
            if (isMobile) {
              await (screen.orientation as any)?.lock("portrait");
            }
          } catch (error) {
            console.error(error);
          }

          // Default to back camera only on mobile for photo camera
          if (isMobile) {
            await startStream(isPhotoEnabled ? "environment" : "user");
          }

          if (!mountedRef.current) {
            // We need to stop stream here as well because sometimes the camera can be opened and closed
            // quickly whilte the promises inside startStream are still resolving so the stopStream present
            // in the return of this hook is executed before the stream starts and thus the camera continues
            // active forever
            stopStream();

            return;
          }

          const devices = await getDevices();

          const storageSelectedDevices =
            localStorage.getItem("selectedDevices");
          let storageSelectedDevicesJson: SelectedDevices | undefined;

          if (storageSelectedDevices) {
            try {
              storageSelectedDevicesJson = JSON.parse(storageSelectedDevices);
            } catch {
              console.warn("no settings saved");
            }
          }

          // Set initial state of config modal inputs
          let audioDevice: MediaDeviceInfo | undefined;
          if (isVideoEnabled) {
            const storageSelectedDevicesAudioId =
              storageSelectedDevicesJson?.audioDevice?.deviceId;

            audioDevice = devices.find(
              (device) => device.deviceId === storageSelectedDevicesAudioId
            );

            if (!audioDevice) {
              audioDevice = devices.find(
                (device) =>
                  device.deviceId === "default" && device.kind === "audioinput"
              );

              if (!audioDevice) {
                audioDevice = devices.find(
                  (device) => device.kind === "audioinput"
                );
              }
            }
          }

          const storageSelectedDevicesAudioId =
            storageSelectedDevicesJson?.videoDevice?.deviceId;
          let videoDevice = devices.find(
            (device) => device.deviceId === storageSelectedDevicesAudioId
          );

          if (!videoDevice) {
            videoDevice = devices.find(
              (device) =>
                device.deviceId === "default" && device.kind === "videoinput"
            );
            if (!videoDevice) {
              videoDevice = devices.find(
                (device) => device.kind === "videoinput"
              );
            }
          }
          if (!isMobile) {
            await startStream(undefined, { audioDevice, videoDevice });
          }

          setSelectedDevices({ audioDevice, videoDevice });

          setIsLoading(false);

          onLoaded?.();
        } catch (error) {
          setIsLoading(false);

          const err = error as Error;

          typewriterTrack("webcamInitiationFailed", {
            companyExternalId: trackingProps.companyExternalId,
            profileExternalId: trackingProps.profileExternalId,
            errorMessage: err.message,
            errorName: err.name,
          });

          onError?.(err);

          console.error(error);
        }
      };

      void init();

      return () => {
        // Still not fully supported
        // See https://developer.mozilla.org/en-US/docs/Web/API/ScreenOrientation/lock for more details
        if (screen.orientation?.unlock) {
          screen.orientation?.unlock();
        }

        clearCountDownAndReset();
        clearTimer();

        // Ensure all media is properly stopped
        stopStream();

        // Additional cleanup for any remaining audio context
        try {
          if (audioContext.state !== "closed") {
            debugLog("Closing audio context");
            audioContext
              .close()
              .catch((err) =>
                console.error("Error closing audio context:", err)
              );
          }
        } catch (error) {
          console.error("Error during audio context cleanup:", error);
        }

        // Ensure all intervals and timeouts are cleared
        if (timerIntervalRef.current) {
          window.clearInterval(timerIntervalRef.current);
          timerIntervalRef.current = null;
        }

        if (countDownIntervalRef.current) {
          window.clearInterval(countDownIntervalRef.current);
          countDownIntervalRef.current = null;
        }

        mountedRef.current = false;
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      const webcamBackground = localStorage.getItem("webcamBackground");

      if (webcamBackground) {
        try {
          const webcamBackgroundJson = JSON.parse(webcamBackground);

          void onEffectChange(webcamBackgroundJson);
        } catch {
          console.warn("no backround saved");
        }
      }
    }, []);

    const toggleCamera = async () => {
      const currentFacingMode = userMediaStreamRef.current
        ?.getVideoTracks()[0]
        .getConstraints().facingMode;

      await startStream(currentFacingMode === "user" ? "environment" : "user");
    };

    const stopRecording = () => {
      debugLog("Stopping recording");
      if (mediaRecorderRef.current?.state !== "inactive") {
        debugLog(
          `MediaRecorder state before stopping: ${mediaRecorderRef.current?.state}`
        );
        mediaRecorderRef.current?.stop();
        debugLog("MediaRecorder stop called");
      } else {
        debugLog("MediaRecorder already inactive, nothing to stop");
      }

      if (deviceMediaStreamRef.current?.active) {
        debugLog("Stopping active screen share");
        stopScreenShare();
      }

      debugLog("Clearing timer");
      clearTimer();

      setHasShootStarted(undefined);
      debugLog("hasShootStarted set to undefined");
    };

    const startRecording = useCallback(() => {
      debugLog("Starting recording");
      if (!canvasRef.current || !userMediaStreamRef.current) {
        errorLog("Cannot start recording: canvas or user media stream is null");

        return;
      }

      // Pre-initialize audio mixing
      debugLog("Setting up audio mixing");
      if (
        userMediaStreamRef.current.getAudioTracks().length &&
        !currentUserAudioSourceRef.current
      ) {
        debugLog("Creating audio source from user media stream");
        const userSource = audioContext.createMediaStreamSource(
          userMediaStreamRef.current
        );

        const userGain = audioContext.createGain();
        userGain.gain.value = 1;
        userSource.connect(userGain).connect(mediaStreamAudioDestinationNode);
        debugLog("Audio source connected to destination node");

        currentUserAudioSourceRef.current = userSource;
      }

      // Capture the stream
      debugLog("Capturing canvas stream");
      const stream = canvasRef.current.captureStream();
      debugLog("Canvas stream captured", {
        videoTracks: stream.getVideoTracks().length,
      });

      // Add audio tracks
      debugLog("Adding audio tracks to stream");
      const audioTracks =
        mediaStreamAudioDestinationNode.stream.getAudioTracks();
      debugLog("Audio tracks available:", audioTracks.length);
      audioTracks.forEach((track) => {
        stream.addTrack(track);
        debugLog(`Added audio track: ${track.label}`);
      });

      // Initialize MediaRecorder with appropriate settings
      debugLog("Initializing MediaRecorder");
      try {
        if (MediaRecorder.isTypeSupported(EXPORTED_VIDEO_FORMAT)) {
          debugLog(`Using preferred format: ${EXPORTED_VIDEO_FORMAT}`);
          mediaRecorderRef.current = new MediaRecorder(stream, {
            mimeType: EXPORTED_VIDEO_FORMAT,
            videoBitsPerSecond: 2500000, // 2.5 Mbps for better quality
          });
        } else {
          debugLog(`Using fallback format: ${FALLBACK_EXPORTED_VIDEO_FORMAT}`);
          mediaRecorderRef.current = new MediaRecorder(stream, {
            mimeType: FALLBACK_EXPORTED_VIDEO_FORMAT,
            videoBitsPerSecond: 2500000,
          });
        }
        debugLog("MediaRecorder initialized successfully");
      } catch (error) {
        errorLog("Error trying to instantiate MediaRecorder:", error);
        debugLog("Trying with default format");
        mediaRecorderRef.current = new MediaRecorder(stream);
      }

      // Set up data handling
      debugLog("Setting up data handling for recording");
      const chunks: BlobPart[] = [];
      mediaRecorderRef.current.addEventListener("dataavailable", ({ data }) => {
        debugLog(`Data available event, data size: ${data.size}`);
        if (data.size > 0) {
          chunks.push(data);
          debugLog(`Chunk added, total chunks: ${chunks.length}`);
        } else {
          errorLog("Received empty data chunk");
        }
      });

      mediaRecorderRef.current.addEventListener("stop", () => {
        debugLog("MediaRecorder stop event triggered");
        debugLog(`Total chunks collected: ${chunks.length}`);
        if (chunks.length > 0) {
          debugLog("Creating blob from chunks");
          const format = MediaRecorder.isTypeSupported(EXPORTED_VIDEO_FORMAT)
            ? EXPORTED_VIDEO_FORMAT
            : FALLBACK_EXPORTED_VIDEO_FORMAT;

          debugLog(`Using format for blob: ${format}`);
          const blob = new Blob(chunks, { type: format });
          debugLog(`Blob created, size: ${blob.size}`);

          const currentFacingMode = userMediaStreamRef.current
            ?.getVideoTracks()[0]
            .getConstraints().facingMode;

          debugLog("Creating file from blob");
          const file = new File(
            [blob],
            `user-video-upload-${new Date().getMilliseconds()}`,
            { type: format }
          );
          debugLog(`File created, size: ${file.size}, type: ${file.type}`);

          debugLog("Calling onRecordEnds with file");
          onRecordEnds(
            file,
            currentFacingMode === "user" ? "frontCamera" : "rearCamera"
          );
        } else {
          errorLog("No data available for recording");
        }
      });

      mediaRecorderRef.current.addEventListener("error", (event) => {
        errorLog("MediaRecorder error event:", event);
      });

      // Start recording with a small timeslice to reduce initial delay
      if (mediaRecorderRef.current.state !== "recording") {
        debugLog("Starting MediaRecorder with 100ms timeslice");
        mediaRecorderRef.current.start(100); // Use smaller timeslice (100ms)
        debugLog(`MediaRecorder state: ${mediaRecorderRef.current.state}`);
        onRecordStarts?.();
        debugLog("onRecordStarts callback called");
      } else {
        debugLog(
          `MediaRecorder already in recording state: ${mediaRecorderRef.current.state}`
        );
      }
    }, [
      audioContext,
      mediaStreamAudioDestinationNode,
      onRecordEnds,
      onRecordStarts,
    ]);

    const startTimer = () => {
      let time = 0;
      timerIntervalRef.current = window.setInterval(() => {
        setSeconds((prevState) => prevState + 1);
        onTimeUpdate?.(++time);
      }, 1000);
    };

    const takePhoto = () => {
      if (deviceMediaStreamRef.current?.active) {
        stopScreenShare();
      }

      canvasRef.current?.toBlob(
        (blob) => {
          if (blob) {
            const currentFacingMode = userMediaStreamRef.current
              ?.getVideoTracks()[0]
              .getConstraints().facingMode;

            onRecordEnds(
              new File(
                [blob],
                `user-photo-upload-${new Date().getMilliseconds()}`,
                { type: EXPORTED_IMAGE_FORMAT }
              ),
              currentFacingMode === "user" ? "frontCamera" : "rearCamera"
            );
          }

          setHasShootStarted(undefined);
        },
        EXPORTED_IMAGE_FORMAT,
        1
      );
    };

    const startCountDown = (type: ShootType) => {
      debugLog(`Starting countdown for ${type}`);
      setHasShootStarted(type);

      countDownIntervalRef.current = window.setInterval(() => {
        setCountDown((prevState) => {
          debugLog(`Countdown: ${prevState}`);
          if (prevState === 1 && countDownIntervalRef.current) {
            debugLog("Countdown reached 1, clearing and starting capture");
            clearCountDownAndReset();

            if (type === ShootType.VIDEO) {
              debugLog("Starting video recording");
              startRecording();
              debugLog("Starting timer");
              startTimer();
            } else {
              debugLog("Taking photo");
              takePhoto();
            }
          }

          return prevState - 1;
        });
      }, 1000);

      debugLog("Calling onCountDownStarts callback");
      onCountDownStarts?.();
    };

    const stopCountDown = () => {
      clearCountDownAndReset();
      setHasShootStarted(undefined);
    };

    const onShootClick = (type: ShootType) => {
      debugLog(`Shoot button clicked for ${type}`);
      if (!hasShootStarted) {
        debugLog("No shoot in progress, starting countdown");

        return startCountDown(type);
      }

      if (countDownIntervalRef.current) {
        debugLog("Countdown in progress, stopping it");

        return stopCountDown();
      }

      if (type === ShootType.VIDEO) {
        debugLog("Video recording in progress, stopping it");
        stopRecording();
      }
    };

    const onUploadFileClick = useCallback(() => {
      const input = document.createElement("input");
      document.body.appendChild(input);
      input.type = "file";
      input.accept = "";
      if (isPhotoEnabled) {
        input.accept += ",image/*";
      }
      if (isVideoEnabled) {
        input.accept = "video/mp4, video/x-m4v, video/webm, video/quicktime";
      }
      input.onchange = function (event) {
        const file = (event.target as HTMLInputElement)?.files?.[0];

        if (!file) {
          input.remove();

          return;
        }

        if (
          (isVideoEnabled && !file.type.startsWith("video/")) ||
          (isPhotoEnabled && !file.type.startsWith("image/"))
        ) {
          input.remove();

          return onError?.(new Error("File is not supported"));
        }

        onFileUploaded(file);
        input.remove();
      };

      input.click();
    }, [isPhotoEnabled, isVideoEnabled, onError, onFileUploaded]);

    const onOptionClick = useCallback(
      async (type: OptionType | MenuOptionType) => {
        debugLog(`Option clicked: ${type}`);
        let result = false;

        switch (type) {
          case MenuOptionType.PRESENTATION:
            if (!options.presentation) {
              debugLog("Starting screen share");
              result = await startScreenShare();
              debugLog(`Screen share result: ${result}`);

              typewriterTrack("recorderOptionClicked", {
                appSource: trackingProps.appSource as AppSource,
                responseType: isVideoEnabled
                  ? ResponseType.Video
                  : ResponseType.Image,
                buttonType: ButtonType.RecordScreenStarted,
              });
            } else {
              debugLog("Stopping screen share");
              stopScreenShare();
              result = false;

              typewriterTrack("recorderOptionClicked", {
                appSource: trackingProps.appSource as AppSource,
                responseType: isVideoEnabled
                  ? ResponseType.Video
                  : ResponseType.Image,
                buttonType: ButtonType.RecordScreenStopped,
              });
            }

            setOptions((prevState) => ({ ...prevState, presentation: result }));
            debugLog(`Presentation option set to: ${result}`);

            return result;
          case OptionType.BACKGROUNDS:
            setIsVisualEffectsModalOpen((prevState) => {
              result = !prevState;

              return result;
            });

            typewriterTrack("recorderOptionClicked", {
              appSource: trackingProps.appSource as AppSource,
              responseType: isVideoEnabled
                ? ResponseType.Video
                : ResponseType.Image,
              buttonType: ButtonType.Backgrounds,
            });

            return result;
          case OptionType.SETTINGS:
            setIsSettingsModalOpen((prevState) => {
              result = !prevState;

              return result;
            });

            typewriterTrack("recorderOptionClicked", {
              appSource: trackingProps.appSource as AppSource,
              responseType: isVideoEnabled
                ? ResponseType.Video
                : ResponseType.Image,
              buttonType: ButtonType.Settings,
            });

            return result;
          case OptionType.UPLOAD_FILE:
            onUploadFileClick();

            typewriterTrack("recorderOptionClicked", {
              appSource: trackingProps.appSource as AppSource,
              responseType: isVideoEnabled
                ? ResponseType.Video
                : ResponseType.Image,
              buttonType: ButtonType.Upload,
            });

            return true;
          case OptionType.MIC:
            setOptions((prevState) => {
              result = !prevState.mic;
              if (userMediaStreamRef.current) {
                userMediaStreamRef.current.getAudioTracks()[0].enabled = result;
              }

              return { ...prevState, mic: result };
            });

            typewriterTrack("recorderOptionClicked", {
              appSource: trackingProps.appSource as AppSource,
              responseType: isVideoEnabled
                ? ResponseType.Video
                : ResponseType.Image,
              buttonType: result ? ButtonType.MicOn : ButtonType.MicOff,
            });

            return result;
          case OptionType.CAMERA:
            result = !options.camera;

            effectsRef.current.camera = result;
            if (result) {
              setIsLoading(true);

              const currentFacingMode = userMediaStreamRef.current
                ?.getVideoTracks()[0]
                .getConstraints().facingMode as FacingMode;

              await startStream(currentFacingMode, selectedDevices);

              // Update audio context with new one
              if (
                currentUserAudioSourceRef.current &&
                userMediaStreamRef.current
              ) {
                const newUserAudioSource = audioContext.createMediaStreamSource(
                  userMediaStreamRef.current
                );

                newUserAudioSource.connect(mediaStreamAudioDestinationNode);
                currentUserAudioSourceRef.current.disconnect();

                currentUserAudioSourceRef.current = newUserAudioSource;
              }

              setIsLoading(false);
            } else if (userMediaStreamRef.current) {
              userMediaStreamRef.current
                .getVideoTracks()
                .forEach((track) => track.stop());
            }

            setOptions((prevState) => ({ ...prevState, camera: result }));

            typewriterTrack("recorderOptionClicked", {
              appSource: trackingProps.appSource as AppSource,
              responseType: isVideoEnabled
                ? ResponseType.Video
                : ResponseType.Image,
              buttonType: result ? ButtonType.CameraOn : ButtonType.CameraOff,
            });

            return result;
          default:
            throw new Error(`Unknown option type: ${type}`);
        }
      },
      [
        audioContext,
        isVideoEnabled,
        mediaStreamAudioDestinationNode,
        onUploadFileClick,
        options.camera,
        options.presentation,
        selectedDevices,
        startScreenShare,
        startStream,
        stopScreenShare,
        trackingProps.appSource,
      ]
    );

    useImperativeHandle(ref, () => ({
      onOptionClick(type: OptionType | MenuOptionType) {
        return onOptionClick(type);
      },
    }));

    const onSettingsChange = async (selectedDevices: SelectedDevices) => {
      localStorage.setItem("selectedDevices", JSON.stringify(selectedDevices));
      setSelectedDevices(selectedDevices);

      const currentFacingMode = userMediaStreamRef.current
        ?.getVideoTracks()[0]
        .getConstraints().facingMode as FacingMode;

      void startStream(currentFacingMode, selectedDevices);
    };

    const onEffectChange = async (effect: Effect<EffectType>) => {
      localStorage.setItem("webcamBackground", JSON.stringify(effect));

      if (!modelRef.current) {
        await tf.setBackend("wasm");
        await tf.ready();
        modelRef.current = await createSegmenter(
          SupportedModels.MediaPipeSelfieSegmentation,
          {
            runtime: "mediapipe",
            modelType: "general",
            solutionPath: `https://cdn.jsdelivr.net/npm/@mediapipe/selfie_segmentation@${selfieSegmentation.VERSION}`,
          }
        );
      }

      switch (effect.type) {
        case EffectType.IMAGE: {
          effectsRef.current.blur = 0;

          const img = new Image();
          img.src = effect.value as string;
          img.crossOrigin = "anonymous";
          img.onload = function () {
            effectsRef.current.background = img;
          };
          break;
        }
        case EffectType.BLUR:
          effectsRef.current.background = false;
          effectsRef.current.blur = effect.value as number;
          break;
        case EffectType.NONE:
          effectsRef.current.background = false;
          effectsRef.current.blur = 0;
          break;
        default:
          throw new Error(`Unknown effect type: ${effect.type}`);
      }

      typewriterTrack("cameraBackgroundToggled", {
        appSource: trackingProps.appSource as AppSource,
        backgroundType: effect.type as unknown as BackgroundType,
      });
    };

    return (
      <WebcamView
        canvasRef={canvasRef}
        videoDim={videoDim}
        isSettingsModalOpen={isSettingsModalOpen}
        isVisualEffectsModalOpen={isVisualEffectsModalOpen}
        isPhotoEnabled={isPhotoEnabled}
        isVideoEnabled={isVideoEnabled}
        isLoading={isLoading}
        hasShootStarted={hasShootStarted}
        showInfinityMirrorWarning={
          options.presentation &&
          isSharingScreenOrBrowserWindow.current &&
          !hideInfinityMirrorWarning
        }
        showCountDown={!!countDownIntervalRef.current && countDown > 0}
        availableDevices={availableDevices}
        selectedDevices={selectedDevices}
        countDown={countDown}
        options={options}
        seconds={seconds}
        localizations={localizations}
        onHideInfinityMirrorWarning={() => setHideInfinityMirrorWarning(true)}
        onShootClick={onShootClick}
        onToggleCameraClick={toggleCamera}
        onOptionClick={onOptionClick}
        onSettingsChange={onSettingsChange}
        onEffectChange={onEffectChange}
      />
    );
  }
);

Webcam.displayName = "Webcam";
