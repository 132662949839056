import React, { MouseEvent, PropsWithChildren } from "react";
import { BaseCard, CardProps } from "../../../../../BaseCard";
import { CtaButton } from "../../../../../../components/CtaButton";
import { AlignedContent } from "../../../../../../styles";
import { Title } from "../../../../../../components/Title";
import { Wrapper } from "../../../../../CompletionCard/styles";

type Props = CardProps & {
  title: string;
  ctaText: string;
  showCtaButton: boolean;
  retryLoading?: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
};

export function BaseCompletionCard({
  isActive,
  children,
  title,
  ctaText,
  showCtaButton,
  retryLoading,
  onClick,
  onCardActive,
}: PropsWithChildren<Props>) {
  return (
    <Wrapper>
      <BaseCard isActive={isActive} onCardActive={onCardActive}>
        <AlignedContent $align="center">
          <Title text={title} />
          {children}
          {showCtaButton && (
            <CtaButton
              text={ctaText}
              onClick={onClick}
              loading={retryLoading}
              disabled={retryLoading}
            />
          )}
        </AlignedContent>
      </BaseCard>
    </Wrapper>
  );
}
